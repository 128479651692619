import { callApi } from 'App/state'
import { createGeopoint } from 'state/utils'
import {
  addEntitiesToStore,
  docToEntity,
  orgRef,
  makeEntityDuck
} from 'App/state/utils'

const stripeActions = [
  'CREATE_CUSTOM_STRIPE_ACCOUNT',
  'CREATE_CUSTOM_STRIPE_ACCOUNT_SUCCESS',
  'CREATE_CUSTOM_STRIPE_ACCOUNT_FAIL'
]
export const createCustomStripeAccount = ({ ipAddress, orgId, locId }) =>
  callApi({
    method: 'POST',
    types: stripeActions,
    endpoint: 'stripe/createCustomAccount',
    data: {
      ipAddress,
      orgId,
      locId
    }
  })
export const getLocationEntity = ({ orgId, locId }) => dispatch =>
  orgRef
    .doc(orgId)
    .collection('locations')
    .doc(locId)
    .onSnapshot(doc =>
      dispatch(addEntitiesToStore('locations', docToEntity(doc)))
    )

const pickUpSchedule = makeEntityDuck({ collectionName: 'pickUpSchedules' })
export const getPickUpScheduleEntities = pickUpSchedule.getter

const deliverySchedule = makeEntityDuck({
  collectionName: 'deliverySchedules'
})
export const getDeliveryScheduleEntities = deliverySchedule.getter

const menus = makeEntityDuck({ collectionName: 'menus' })
export const getMenuEntities = menus.getter

export const addGeopoint = ({ orgId, locId }) => coords =>
  orgRef
    .doc(orgId)
    .collection('locations')
    .doc(locId)
    .update({ 'address.geopoint': createGeopoint(coords) })
    .then(() => console.log('update geopoint yay!'))

export const updateLocationEntity = ({ orgId, locId, locData }) => dispatch =>
  orgRef
    .doc(orgId)
    .collection('locations')
    .doc(locId)
    .update(locData)
    .then(() => console.log('yay!'))

const mapTypeToDocName = {
  pickup: 'pickUpSchedules',
  delivery: 'deliverySchedules'
}
export const createSchedule = scheduleType => ({
  orgId,
  locId
}) => schedule => dispatch =>
  orgRef
    .doc(orgId)
    .collection(mapTypeToDocName[scheduleType])
    .add(schedule)
    .then(doc => {
      dispatch(
        updateLocationEntity({
          orgId,
          locId,
          locData: { [mapTypeToDocName[scheduleType]]: doc.id }
        })
      )
    })

export const updateSchedule = scheduleType => ({
  orgId
}) => schedule => dispatch =>
  orgRef
    .doc(orgId)
    .collection(mapTypeToDocName[scheduleType])
    .doc(schedule.id)
    .update(schedule)

export default {
  pickUpSchedules: pickUpSchedule.reducer,
  deliverySchedules: deliverySchedule.reducer,
  menus: menus.reducer
}
