import React from 'react'
import { connect } from 'react-redux'
import pt from 'prop-types'
import cc from 'create-react-class'
import {
  Flex,
  Box,
  Button,
  Text,
  Title,
  Input,
  Textarea,
  Select,
  CheckboxGroup,
  CheckboxOption
} from 'boostly-ui'
import { pluck, arrayIndexReplace } from 'utils'
import { Tab, Tabs, TabPanel } from 'App/shared/TabComponents'
import { loadCampaign, updateCampaign, select } from './state'
import { format } from 'date-fns'

const Label = ({ label, children }) => (
  <Flex pr={2} pb={1} align="center">
    <Text>{label || children}</Text>
  </Flex>
)
const InputField = ({ textarea, label, inputRef, width = '100%', ...rest }) => (
  <Flex column py={1} w={width}>
    <Label label={label} />
    <Box>{textarea ? <Textarea {...rest} /> : <Input {...rest} />}</Box>
  </Flex>
)
const ManageCampaign = cc({
  propTypes: {
    campaign: pt.shape({
      orgId: pt.string,
      campaignStartDate: pt.date,
      referralOptions: pt.array,
      socialPlatformSettings: pt.object
    })
  },
  getInitialState() {
    return { campaign: this.props.campaign, errors: [] }
  },
  maybeSetCampaign(campaign) {
    if (!this.state.campaign.id) {
      this.setState({ campaign })
    }
  },
  componentWillReceiveProps(next) {
    this.maybeSetCampaign(next.campaign)
  },
  onCampaignSave() {
    const { campaign } = this.state
    this.props.updateCampaign(campaign.id, pluck(['id'], campaign))
    this.setState(prev => ({ changesWereMade: false }))
  },
  onValueChange(key) {
    return ({ target }) =>
      this.setState(prev => ({
        changesWereMade: true,
        campaign: { ...prev.campaign, [key]: target.value }
      }))
  },
  componentDidMount() {
    this.props.loadCampaign(this.props.match.params.campaignId)
  },
  validateDiscountIncentive({ name, type, value }) {
    return !!name && !!type && (!!value || value == 0)
      ? { isValid: true, validatedData: { name, type, value: Number(value) } }
      : { isValid: false }
  },
  validateItemIncentive({ name, price, quantity }) {
    return !!name && (!!price || price == 0) && (!!quantity || quantity == 0)
      ? {
          isValid: true,
          validatedData: {
            name,
            price: Number(price),
            quantity: Number(quantity)
          }
        }
      : { isValid: false }
  },
  validateOption(option) {
    let errors = []
    let validOption = {}
    const detailNames = ['redeemedIncentiveDetails', 'referredIncentiveDetails']
    detailNames.forEach(prop => {
      const incentiveDetails = option[prop]
      let result
      if (incentiveDetails.type === 'DISCOUNT') {
        result = this.validateDiscountIncentive(incentiveDetails.meta)
      } else {
        result = this.validateItemIncentive(incentiveDetails.meta)
      }

      if (!incentiveDetails.limit) {
        errors.push(`${prop} is missing a limit property`)
      }
      if (result.isValid) {
        validOption = {
          ...validOption,
          [prop]: { ...incentiveDetails, meta: result.validatedData }
        }
      } else {
        errors.push(`Meta for ${prop} is invalid`)
      }
    })

    // if (!option.numberOfReferrals)
    return errors.length
      ? { isValid: false, errors }
      : { isValid: true, validatedData: { ...option, ...validOption } }
  },
  onOptionSave(index) {
    return option => {
      const result = this.validateOption(option)
      if (result.isValid) {
        this.setState(prev => ({
          changesWereMade: true,
          campaign: {
            ...prev.campaign,
            referralOptions: arrayIndexReplace(
              prev.campaign.referralOptions,
              result.validatedData,
              index
            )
          }
        }))
      } else {
        this.setState(prev => ({ errors: result.errors }))
      }
    }
  },
  changesWereMade() {
    this.setState(prev => ({ changesWereMade: true }))
  },
  onSubmit(e) {
    e.preventDefault()
  },
  render() {
    const { campaign, errors } = this.state
    return (
      <Box height="100%">
        <Box
          paddingTop="5%"
          is="form"
          onSubmit={this.onSubmit}
          w="100%"
          margin="0 auto"
          p={1}
        >
          <Box pb={1}>
            <Flex justify="flex-end">
              <Box w="250px">
                <Button
                  onClick={this.onCampaignSave}
                  disabled={!this.state.changesWereMade}
                >
                  Save Campaign
                </Button>
              </Box>
            </Flex>
            <Label>Start Date</Label>
            <Text fontSize={2}>
              {format(campaign.campaignStartDate, 'MMM, do YYYY')}
            </Text>
            <Box py={1} />
            <InputField
              label="Campaign Name"
              value={campaign.name}
              onChange={this.onValueChange('name')}
            />
          </Box>
          <hr />
          <Box py={2} />
          <Box>
            <Title fontSize={3}>Referral Options</Title>
            <Box is="ul">
              {errors.length
                ? errors.map((e, i) => (
                    <Box key={i} is="li">
                      <Text error>{e}</Text>
                    </Box>
                  ))
                : undefined}
            </Box>
            {(campaign.referralOptions || []).map((op, i) => (
              <Box key={i}>
                <ReferralOption {...op} onOptionSave={this.onOptionSave(i)} />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    )
  }
})

const simpleItemShape = {
  IGNORE_ORDER_REQS: pt.bool,
  name: pt.string,
  price: pt.number,
  quantity: pt.number
}
const discountShape = { name: pt.string, value: pt.number, type: pt.string }
const wrapAsEventObject = value => ({ target: { value } })
const validateIncentiveDetails = props => {}
// pt.shape({
//   limit: pt.string,
//   type: pt.string,
//   meta: pt.oneOf([ pt.shape(simpleItemShape), pt.shape(discountShape) ])
// })
const ReferralOption = cc({
  propTypes: {
    incentiveCopy: pt.string,
    incentiveItem: pt.string,
    numberOfReferrals: pt.number,
    redeemedIncentiveDetails: validateIncentiveDetails,
    referredIncentiveDetails: validateIncentiveDetails,
    socialPlatformSettings: pt.shape({
      facebook: pt.shape({
        title: pt.string,
        image: pt.string,
        description: pt.string
      })
    })
  },
  getInitialState() {
    return { changesWereMade: false, ...pluck(['onOptionSave'], this.props) }
  },
  onOptionSave() {
    this.props.onOptionSave(pluck(['changesWereMade'], this.state))
    this.setState(prev => ({ changesWereMade: false }))
  },
  onAddressChange(key) {
    return ({ target }) => {
      this.setState(prev => ({
        address: { ...prev.address, [key]: target.value }
      }))
      this.changesWereMade()
    }
  },
  changesWereMade() {
    this.setState(prev => ({ changesWereMade: true }))
  },
  onValueChange(key) {
    return ({ target }) => {
      this.setState(prev => ({ [key]: target.value }))
      this.changesWereMade()
    }
  },
  incentiveTypeToDetailKey: {
    referred: 'referredIncentiveDetails',
    redeemed: 'redeemedIncentiveDetails'
  },
  onIncentiveDetailChange(type) {
    const detailKey = this.incentiveTypeToDetailKey[type]
    return key => ({ target }) => {
      this.setState(prev => ({
        [detailKey]: { ...prev[detailKey], [key]: target.value }
      }))
      this.changesWereMade()
    }
  },
  onIncentiveMetaChange(type) {
    const detailKey = this.incentiveTypeToDetailKey[type]
    return key => ({ target }) => {
      this.setState(prev => ({
        [detailKey]: {
          ...prev[detailKey],
          meta: { ...prev[detailKey].meta, [key]: target.value }
        }
      }))
      this.changesWereMade()
    }
  },
  renderSimpleItemMeta(incentiveType) {
    let onMetaChange = this.onIncentiveMetaChange(incentiveType)
    return ({ name, price, quantity, IGNORE_ORDER_REQS }) => (
      <Box px={2}>
        <InputField
          label="Item Name"
          value={name}
          onChange={onMetaChange('name')}
        />
        <Flex>
          <InputField
            type="number"
            label="Item Quantity"
            value={quantity}
            onChange={onMetaChange('quantity')}
          />
          <Box px={1} />
          <InputField
            type="number"
            label="Item Price (in dollars)"
            value={price}
            onChange={onMetaChange('price')}
          />
        </Flex>
        <CheckboxGroup
          onChange={val =>
            onMetaChange('IGNORE_ORDER_REQS')(
              wrapAsEventObject(!IGNORE_ORDER_REQS)
            )
          }
          value={[IGNORE_ORDER_REQS === true ? 'ignore' : undefined]}
        >
          <CheckboxOption checked={IGNORE_ORDER_REQS} value="ignore">
            Ignore Min Order Reqs
          </CheckboxOption>
        </CheckboxGroup>
      </Box>
    )
  },
  renderDiscountMeta(incentiveType) {
    let onMetaChange = this.onIncentiveMetaChange(incentiveType)
    return ({ type, value, name }) => (
      <Box px={2}>
        <Box>
          <Label label="Type" />
          <Select label="type" value={type} onChange={onMetaChange('type')}>
            <option>Choose a discount type</option>
            <option>PERCENT_OFF</option>
            <option>SUBTRACT</option>
          </Select>
        </Box>
        <Flex>
          <InputField
            label="Discount Name"
            value={name}
            onChange={onMetaChange('name')}
          />
          <Box px={1} />
          <InputField
            type="number"
            label={`Discount Value ${
              type === 'PERCENT_OFF'
                ? '(0.1 = 10%)'
                : type === 'SUBTRACT'
                ? '(in dollars)'
                : ''
            }`}
            value={value}
            onChange={onMetaChange('value')}
          />
        </Flex>
      </Box>
    )
  },
  incentiveTypeToRenderFn(type) {
    return {
      SIMPLE_ITEM: this.renderSimpleItemMeta,
      DISCOUNT: this.renderDiscountMeta
    }[type]
  },
  onFacebookValueChanges(key) {
    return ({ target }) => {
      this.setState(prev => ({
        changesWereMade: true,
        socialPlatformSettings: {
          facebook: {
            ...prev.socialPlatformSettings.facebook,
            [key]: target.value
          }
        }
      }))
    }
  },
  render() {
    const {
      incentiveItem,
      numberOfReferrals,
      incentiveCopy,
      shareCopy,
      socialPlatformSettings
    } = this.state
    return (
      <Box>
        <Flex justify="flex-end">
          <Box w="200px">
            <Button
              onClick={this.onOptionSave}
              disabled={!this.state.changesWereMade}
            >
              Save Option
            </Button>
          </Box>
        </Flex>
        <InputField
          label="Incentive Item"
          value={incentiveItem}
          onChange={this.onValueChange('incentiveItem')}
        />
        <InputField
          type="number"
          label="Number Of Referrals"
          value={numberOfReferrals}
          onChange={this.onValueChange('numberOfReferrals')}
        />
        <InputField
          textarea
          label="Incentive Copy"
          value={incentiveCopy}
          onChange={this.onValueChange('incentiveCopy')}
        />
        <InputField
          textarea
          label="Share Copy"
          value={shareCopy}
          onChange={this.onValueChange('shareCopy')}
        />
        <Tabs>
          <Tab>
            <Title>Redeemed Insentive</Title>
          </Tab>
          <Tab>
            <Title>Referred Insentive</Title>
          </Tab>
          {['redeemed', 'referred'].map((type, i) => {
            const entity = this.state[`${type}IncentiveDetails`]
            return (
              <TabPanel key={i}>
                <Flex justify="space-between">
                  <Box w="48%">
                    <Label label="Type" />
                    <Select
                      label="type"
                      value={entity.type}
                      onChange={this.onIncentiveDetailChange(type)('type')}
                    >
                      <option>DISCOUNT</option>
                      <option>SIMPLE_ITEM</option>
                    </Select>
                  </Box>
                  <Box w="48%">
                    <Label label="Limit" />
                    <Select
                      label="Limit"
                      value={entity.limit}
                      onChange={this.onIncentiveDetailChange(type)('limit')}
                    >
                      <option>Choose a limit</option>
                      <option>NONE</option>
                      <option>SINGLE_USE</option>
                    </Select>
                  </Box>
                </Flex>
                <Box pt={2}>
                  <Title fontSize={2}>Promo Details </Title>
                  {this.incentiveTypeToRenderFn(entity.type)(type)(entity.meta)}
                </Box>
              </TabPanel>
            )
          })}
        </Tabs>
        <Box py={2} />
        <Title fontSize={3}>Facebook Settings</Title>
        <SocialFacebook
          {...socialPlatformSettings.facebook}
          onValueChange={this.onFacebookValueChanges}
        />
      </Box>
    )
  }
})

const SocialFacebook = cc({
  boxSize: 158,
  getInitialState() {
    return { imageWidth: 0 }
  },
  setWidth(el) {
    if (!el) return
    const imageWidth = el.getBoundingClientRect().width
    if (imageWidth > 0) {
      this.setState(() => ({ imageWidth }))
    } else {
      window.setTimeout(() => this.setWidth(el), 100)
    }
  },
  render() {
    const { image, title, description } = this.props
    const offset = this.state.imageWidth / 2 - this.boxSize / 2
    return (
      <Box>
        <InputField
          label="Title"
          value={title}
          onChange={this.props.onValueChange('title')}
        />
        <Box
          w={`${this.boxSize}`}
          height={`${this.boxSize}`}
          display="block"
          position="relative"
          overflow="hidden"
        >
          <Box position="absolute" right="79px" left={`-${offset}px`}>
            <img ref={this.setWidth} height="158px" src={image} />
          </Box>
        </Box>
        <InputField
          label="Image Url"
          value={image}
          onChange={this.props.onValueChange('image')}
        />
        <InputField
          textarea
          label="Description"
          value={description}
          onChange={this.props.onValueChange('description')}
        />
      </Box>
    )
  }
})

export default connect(
  (state, props) => ({
    campaign: select.campaign(props.match.params.campaignId)(state) || {}
  }),
  dispatch => ({
    loadCampaign: id => dispatch(loadCampaign(id)),
    updateCampaign: (id, data) => dispatch(updateCampaign(id)(data))
  })
)(ManageCampaign)
