import { createAsyncActions as aa } from 'state/utils'
import { callStore } from 'App/state'

export const select = {
  campaign: id => ({ entities }) => entities.campaigns[id]
}

const LOAD_CAMPAIGN_ACTIONS = aa('LOAD_CAMPAIGN')
export const loadCampaign = id =>
  callStore({
    types: LOAD_CAMPAIGN_ACTIONS,
    schema: { name: 'campaigns' },
    query: { method: 'onSnapshot', collection: 'campaigns', doc: id }
  })

const UPDATE_CAMPAIGN_ACTIONS = aa('UPDATE_CAMPAIGN')
export const updateCampaign = id =>
  data =>
    callStore({
      types: UPDATE_CAMPAIGN_ACTIONS,
      query: { method: 'update', collection: 'campaigns', doc: id, data }
    })
