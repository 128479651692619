import React from 'react'
import cc from 'create-react-class'
import {
  SortableContainer,
  SortableElement,
  arrayMove
} from 'react-sortable-hoc'
import { Box, settings as s } from 'boostly-ui'

const SortableItem = SortableElement(({ value }) => (
  <Box
    is='li'
    p={1}
    listStyle='none'
    border={`solid 1px ${s.colors.base}`}
    bg={s.colors.lightBaseHighlight}
  >
    {value}
  </Box>
))

const SortableList = SortableContainer(({ items }) => {
  return (
    <Box is='ul' border={`solid 1px ${s.colors.base}`}>
      {
        items.map((value, index) => (
          <SortableItem key={`item-${index}`} index={index} value={value} />
        ))
      }
    </Box>
  )
})

const SortableComponent = cc({
  onSortEnd ({ oldIndex, newIndex }) {
    this.props.onSortEnd(arrayMove(this.props.list, oldIndex, newIndex))
  },
  render () {
    return (
      <SortableList items={this.props.listValues} onSortEnd={this.onSortEnd} />
    )
  }
})

export default SortableComponent
