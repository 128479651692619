import { createAsyncActions as aa, createReducer } from 'state/utils'
import { callStore } from 'App/state'
import { startOfToday } from 'date-fns'

const GET_LOCATION_ENTITY_ACTIONS = aa('GET_LOCATION_ENTITY')
export const loadLocationEntity = ({ orgId, locId }) =>
  callStore({
    types: GET_LOCATION_ENTITY_ACTIONS,
    schema: { name: 'locations' },
    query: {
      collection: 'orgs',
      doc: orgId,
      subcollections: [{ collection: 'locations', doc: locId }],
      method: 'onSnapshot'
    }
  })

const APPROVE_ORDER_ACTIONS = aa('APPROVE_ORDER')
export const approveOrder = ({ orderId }) =>
  callStore({
    types: APPROVE_ORDER_ACTIONS,
    query: {
      collection: 'orders',
      doc: orderId,
      method: 'update',
      data: { status: 'inProgress' }
    }
  })

const GET_NEW_ORDER_ENTITIES_ACTIONS = aa('GET_NEW_ORDER_ENTITIES')
export const loadNewOrderEntities = () =>
  callStore({
    types: GET_NEW_ORDER_ENTITIES_ACTIONS,
    schema: { name: 'orders' },
    query: {
      collection: 'orders',
      where: [
        'status == awaitingApproval',
        ['fulfillBy', '>=', startOfToday()]
      ],
      method: 'onSnapshot'
    }
  })

const GET_CONSUMER_ENTITY_ACTIONS = aa('GET_CONSUMER_ENTITY')
export const loadConsumerEntity = id =>
  callStore({
    types: GET_CONSUMER_ENTITY_ACTIONS,
    schema: { name: 'consumers' },
    query: { collection: 'consumers', doc: id, method: 'get' },
    bailout: state => state.entities.consumers[id]
  })

/**
 * defaultCustomer fills the gap for when
 * 1. order is missing a consumer property
 * 2. order is missing info required to be rendered
 */
const defaultCustomer = {
  id: '123',
  firstName: 'Boostly',
  lastName: 'Customer'
}
const selectOrderEntities = type => ({ locId }) => ({ entities, ...rest }) => {
  return (rest[type] || []).map(id => {
    const order = entities.orders[id]
    let consumer = entities.consumers[order.consumer]
    if (consumer && !consumer.firstName) {
      consumer = defaultCustomer
    }
    return {
      ...order,
      consumer: consumer || order.consumer || defaultCustomer
    }
  })
}

export const select = {
  pendingOrders: selectOrderEntities('newOrderIds'),
  locationsById: ({ locId }) => ({ entities }) => entities.locations
}

export default {
  newOrderIds: createReducer([], {
    [GET_NEW_ORDER_ENTITIES_ACTIONS[1]]: (state, { payload, meta }) =>
      payload.ids
  }),
  godModeLoaded: createReducer(false, {
    [GET_NEW_ORDER_ENTITIES_ACTIONS[1]]: () => true,
    [GET_NEW_ORDER_ENTITIES_ACTIONS[2]]: () => true
  })
}
