import React from 'react'
import { Row, settings as s } from 'boostly-ui2'
import { RingLoader } from 'react-spinners'

const Loading = () => (
  <Row x pt="12%">
    <RingLoader color={s.colors.purple} />
  </Row>
)

export default Loading
