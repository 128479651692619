import React from 'react'
import { Row, settings as s } from 'boostly-ui2'

const ListItem = ({ children, onClick }) => (
  <Row
    x
    y
    onClick={onClick}
    border={`solid 2px ${s.colors.purple}`}
    w="100%"
    align="center"
    column
    p={1}
    m={1}
    cursor="pointer"
    transition=".25s"
    css={`
      &:hover {
        color: white;
        background: ${s.colors.purple};
        border: solid 2px ${s.colors.dusk};
      }
    `}
  >
    {children}
  </Row>
)

export default ListItem
