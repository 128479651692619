import React from 'react'
import cc from 'create-react-class'
import pt from 'prop-types'
import { connect } from 'react-redux'
import { RingLoader } from 'react-spinners'
import { Link } from 'react-router-dom'
import dateFns from 'date-fns'
import {
  Flex,
  Title,
  Modal,
  Input,
  CheckboxGroup,
  CheckboxOption,
  RadioGroup,
  RadioOption,
  Select
} from 'boostly-ui'
import { Row, Col, Text, Box, Button, settings as s } from 'boostly-ui2'
import {
  createNewLocation,
  getLocationEntities,
  createNewMenu,
  getMenuEntities,
  getCampaignEntities,
  createAdminEntity,
  createCampaignEntity,
  getAdminEntities,
  makeMenuCopy,
  goLive
} from './actions'
import { getOrgEntity, selectors } from 'App/state'
import ListItem from 'App/shared/ListItem'

const Label = ({ label }) => (
  <Flex pr={2} pb={1} align="center">
    <Text>{label}</Text>
  </Flex>
)
const InputField = ({ label, inputRef, width = '100%', ...rest }) => (
  <Flex column py={1} w={width}>
    <Label label={label} />
    <Box>
      <Input {...rest} />
    </Box>
  </Flex>
)

const CreateLocationForm = cc({
  getInitialState() {
    return {
      phaseIndex: 0,
      finalPhase: false,
      name: '',
      address: { line1: '', city: '', zip: '', state: '' },
      availableMethods: [],
      availableTimings: []
    }
  },
  phaseConfig: [
    { renderMethod: 'renderNameForm' },
    { renderMethod: 'renderAddressForm' },
    { renderMethod: 'renderLogisticsForm' },
    { renderMethod: 'renderConfirmation' }
  ],
  renderNameForm() {
    return (
      <InputField
        autoFocus
        label="Name"
        value={this.state.name}
        onChange={this.onNameChange}
      />
    )
  },
  renderConfirmation() {
    const { name, address, availableTimings, availableMethods } = this.state
    return (
      <Box>
        <Flex>
          <Title>Name: </Title>
          <Text> {name}</Text>
        </Flex>
        <Box>
          <Title>Address: </Title>
          <Box>
            <Text>{address.line1}</Text>
          </Box>
          <Text>
            {address.city}, {address.state} {address.zip}
          </Text>
        </Box>
        <Box>
          <Title>Timings: </Title>
          {availableTimings.map((t, i) => (
            <Box key={i}>
              <Text>* {t}</Text>
            </Box>
          ))}
        </Box>
        <Box>
          <Title>Methods: </Title>
          {availableMethods.map((m, i) => (
            <Box key={i}>
              <Text>* {m}</Text>
            </Box>
          ))}
        </Box>
      </Box>
    )
  },
  renderLogisticsForm() {
    return (
      <Box py={1}>
        <Label label="Select Timings Available" />
        <CheckboxGroup
          selectedValue={this.state.availableTimings}
          onChange={this.onTimingChange}
        >
          <CheckboxOption value="now">
            <Text>Now</Text>
          </CheckboxOption>
          <CheckboxOption value="later">
            <Text>Later</Text>
          </CheckboxOption>
        </CheckboxGroup>
        <Label label="Select Methods Available" />
        <CheckboxGroup
          selectedValue={this.state.availableMethods}
          onChange={this.onMethodChange}
        >
          <CheckboxOption value="pickup">
            <Text>Pick Up</Text>
          </CheckboxOption>
          <CheckboxOption value="delivery">
            <Text>Delivery</Text>
          </CheckboxOption>
          <CheckboxOption value="dinein">
            <Text>Dine In</Text>
          </CheckboxOption>
          <CheckboxOption value="curbside">
            <Text>Curbside</Text>
          </CheckboxOption>
        </CheckboxGroup>
      </Box>
    )
  },
  renderAddressForm() {
    return (
      <Box>
        <InputField
          autoFocus
          label="Address Line 1"
          value={this.state.address.line1}
          onChange={this.onAddressChange('line1')}
        />
        <InputField
          label="City"
          value={this.state.address.city}
          onChange={this.onAddressChange('city')}
        />
        <Flex>
          <InputField
            width="55%"
            label="State"
            value={this.state.address.state}
            onChange={this.onAddressChange('state')}
          />
          <Box w="5%" />
          <InputField
            label="Zip"
            width="40%"
            value={this.state.address.zip}
            onChange={this.onAddressChange('zip')}
          />
        </Flex>
      </Box>
    )
  },
  addressFields: [
    { label: 'Address Line 1', key: 'line1' },
    { label: 'City (Utah not UT)', key: 'city' },
    { label: 'State', key: 'state' },
    { label: 'Zip', key: 'zip' }
  ],
  onNameChange({ target }) {
    this.setState(() => ({ name: target.value }))
  },
  onAddressChange(key) {
    return ({ target }) =>
      this.setState(prev => ({
        address: { ...prev.address, [key]: target.value }
      }))
  },
  onMethodChange(value) {
    this.setState(() => ({ availableMethods: value }))
  },
  onTimingChange(value) {
    this.setState(() => ({ availableTimings: value }))
  },
  handleFormFlow(e) {
    e.preventDefault()
    const { phaseIndex } = this.state
    if (phaseIndex < this.phaseConfig.length - 1) {
      const nextPhaseIndex = phaseIndex + 1
      this.setState(prev => ({
        phaseIndex: nextPhaseIndex,
        finalPhase: nextPhaseIndex === this.phaseConfig.length - 1
      }))
    } else {
      this.props.onSubmit({
        name: this.state.name,
        address: this.state.address,
        availableMethods: this.state.availableMethods,
        availableTimings: this.state.availableTimings
      })
    }
  },
  phaseBack() {
    this.setState(prev => ({
      phaseIndex: prev.phaseIndex - 1,
      finalPhase: prev.phaseIndex + 1 === this.phaseConfig.length - 1
    }))
  },
  render() {
    return (
      <Box is="form" onSubmit={this.handleFormFlow}>
        <Box px={3} pb={2}>
          <Box>
            <Title fontSize={3}>New Location</Title>
          </Box>
          {this[this.phaseConfig[this.state.phaseIndex].renderMethod]()}
        </Box>
        <Flex justify="space-between">
          <Box
            pr={1}
            pl={2}
            py={2}
            visibility={this.state.phaseIndex > 0 ? 'block' : 'hidden'}
            w="50%"
          >
            <Button type="button" onClick={this.phaseBack}>
              Back
            </Button>
          </Box>
          <Box pl={1} pr={2} py={2} w="50%">
            <Button type="submit" theme="secondary">
              {this.state.finalPhase ? 'Create' : 'Next'}
            </Button>
          </Box>
        </Flex>
      </Box>
    )
  }
})

const CreateAdminForm = connect()(
  cc({
    propTypes: { locations: pt.array.isRequired },
    getInitialState() {
      return { email: '', password: '', location: '' }
    },
    onChange(key) {
      return ({ target }) => this.setState({ [key]: target.value })
    },
    createAdmin(e) {
      e.preventDefault()
      const data = {
        locationPermissions: [this.state.location],
        org: this.props.orgId,
        roles: { locationManager: true, master: false, orgManager: false }
      }
      this.props.dispatch(createAdminEntity({ ...this.state, data }))
    },
    render() {
      const { locations } = this.props
      return (
        <Box is="form" onSubmit={this.createAdmin}>
          <Box px={3} pb={2}>
            <Box>
              <Label label="Location" />
              {locations.length ? (
                <Select
                  value={this.state.location}
                  onChange={this.onChange('location')}
                >
                  <option value="new">Select A Location</option>
                  {locations.map((l, i) => (
                    <option value={l.id} key={i}>
                      {l.name}
                    </option>
                  ))}
                </Select>
              ) : (
                <Box height="40px" />
              )}
            </Box>
            <InputField label="Email" onChange={this.onChange('email')} />
            <InputField label="Password" onChange={this.onChange('password')} />
            <Text>(Should be at least 6 characters)</Text>
          </Box>
          <Box p={2}>
            <Button type="submit" theme="secondary">
              Create
            </Button>
          </Box>
        </Box>
      )
    }
  })
)

const CreateCampaignForm = connect()(
  cc({
    propTypes: { orgId: pt.string.isRequired },
    getInitialState() {
      return { name: '' }
    },
    onChange(key) {
      return ({ target }) => this.setState({ [key]: target.value })
    },
    createCampaign(e) {
      e.preventDefault()
      this.props.dispatch(
        createCampaignEntity({
          ...this.state,
          ...{
            orgId: this.props.orgId,
            campaignStartDate: new Date(),
            referralOptions: [
              {
                incentiveCopy: '',
                incentiveItem: '',
                shareCopy: '',
                numberOfReferrals: 3,
                redeemedIncentiveDetails: {
                  limit: '',
                  type: 'DISCOUNT',
                  meta: {}
                },
                referredIncentiveDetails: {
                  limit: '',
                  type: 'DISCOUNT',
                  meta: {}
                },
                socialPlatformSettings: { facebook: {} }
              }
            ]
          }
        })
      )
      this.props.onCreate()
    },
    render() {
      return (
        <Box is="form" onSubmit={this.createCampaign}>
          <Box px={3} pb={2}>
            <InputField label="Name" onChange={this.onChange('name')} />
          </Box>
          <Box p={2}>
            <Button type="submit" theme="secondary">
              Create
            </Button>
          </Box>
        </Box>
      )
    }
  })
)

const CreateMenuForm = cc({
  getInitialState() {
    return { name: '', menuToCopy: '' }
  },
  onNameChange({ target }) {
    this.setState(prev => ({ name: target.value }))
  },
  onMenuSelect(value) {
    this.setState(prev => ({ menuToCopy: value }))
  },
  onSubmitRequest(e) {
    e.preventDefault()
    const { name, menuToCopy } = this.state
    if (menuToCopy) {
      this.props.onCopySubmit({ name, menuToCopy })
    } else {
      this.props.onSubmit({ name })
    }
  },
  render() {
    return (
      <Box is="form" onSubmit={this.onSubmitRequest}>
        <Box px={3} pb={2}>
          <Title fontSize={3}>New Menu</Title>
          <Box pb={1} />
          <InputField
            autoFocus
            label="Menu Name"
            value={this.state.name}
            onChange={this.onNameChange}
          />
        </Box>
        <Box px={3}>
          <Title fontSize={3}>Optional Menus To Copy</Title>
          <Box pb={1} />
          <RadioGroup
            selectedValue={this.state.menuToCopy}
            onChange={this.onMenuSelect}
          >
            {this.props.existingMenus.map(menu => (
              <Box pb={1}>
                <RadioOption value={menu.id}>
                  <Text>{menu.name}</Text>
                </RadioOption>
              </Box>
            ))}
          </RadioGroup>
        </Box>
        <Box p={2}>
          <Button type="submit" theme="secondary">
            Create
          </Button>
        </Box>
      </Box>
    )
  }
})

const ManageOrg = cc({
  propTypes: { orgData: pt.object },
  getInitialState() {
    return {
      showCreateLocationModal: false,
      showCreateMenuModal: false,
      showCreateAdminForm: false,
      showModal: ''
    }
  },
  componentDidMount() {
    const {
      orgId,
      getOrgEntity,
      getLocationEntities,
      getMenuEntities,
      getAdminEntities,
      getCampaignEntities
    } = this.props
    getOrgEntity(orgId)
    getLocationEntities(orgId)
    getMenuEntities(orgId)
    getAdminEntities(orgId)
    getCampaignEntities(orgId)
  },
  closeCreationModal() {
    this.setState(prev => ({ showModal: '' }))
  },
  openCreationModal(modalName) {
    return () => {
      this.setState(prev => ({ showModal: modalName }))
    }
  },
  onCreateLocationSubmit(locationObj) {
    this.props.createLocation(locationObj)
    this.closeCreationModal()
  },
  onCreateMenuSubmit(menuObj) {
    this.props.createMenu(menuObj)
    this.closeCreationModal()
  },
  onCopyMenuSubmit(menuObj) {
    this.props.onCopyMenuSubmit(menuObj)
    this.closeCreationModal()
  },
  getModalProps(name) {
    const configs = {
      location: {
        onFormSubmit: this.onCreateLocationSubmit,
        ModalChild: CreateLocationForm,
        modalChildProps: {}
      },
      menu: {
        onFormSubmit: this.onCreateMenuSubmit,
        ModalChild: CreateMenuForm,
        modalChildProps: {
          existingMenus: this.props.orgData && this.props.orgData.menus,
          onCopySubmit: this.onCopyMenuSubmit
        }
      },
      admin: {
        onFormSubmit: this.onCreateAdminSubmit,
        ModalChild: CreateAdminForm,
        modalChildProps: {
          orgId: this.props.match.params.id,
          locations: this.props.orgData && this.props.orgData.locations
        }
      },
      campaign: {
        onFormSubmit: this.onCreateCampaignSubmit,
        ModalChild: CreateCampaignForm,
        modalChildProps: {
          orgId: this.props.match.params.id,
          onCreate: this.closeCreationModal
        }
      }
    }
    return configs[name] || { onFormSubmit: () => ({}), ModalChild: () => ({}) }
  },
  render() {
    const { ModalChild, onFormSubmit, modalChildProps } = this.getModalProps(
      this.state.showModal
    )
    const {
      name,
      locations,
      menus,
      admins,
      campaigns,
      productionInfo
    } = this.props.orgData
    return (
      <Box mb={100}>
        <Box p={2}>
          <Title fontSize={4}>Manage Your Org Man: {name}</Title>
        </Box>
        <Modal
          contentLabel="modal"
          isOpen={!!this.state.showModal}
          onRequestClose={this.closeCreationModal}
        >
          <Box>
            <ModalChild onSubmit={onFormSubmit} {...modalChildProps} />
          </Box>
        </Modal>
        <Box>
          <Row p={2} y space="between">
            <Text.title fontSize={4}>Locations</Text.title>
            <Row
              x
              y
              onClick={this.openCreationModal('location')}
              cursor="pointer"
            >
              <Text.title size={5} mr={2}>
                +
              </Text.title>
              <Text.title>Create</Text.title>
            </Row>
          </Row>
          <Box>
            {locations &&
              locations.map((loc, i) => (
                <Box w={'100%'} key={i}>
                  <Link
                    to={`${this.props.match.url}/manage-location/${loc.id}`}
                  >
                    <ListItem>
                      <Title>{loc.name}</Title>
                    </ListItem>
                  </Link>
                </Box>
              ))}
          </Box>
        </Box>
        <Box mt={3}>
          <Row p={2} y space="between">
            <Text.title fontSize={4}>Menus</Text.title>
            <Row x y onClick={this.openCreationModal('menu')} cursor="pointer">
              <Text.title size={5} mr={2}>
                +
              </Text.title>
              <Text.title>Create</Text.title>
            </Row>
          </Row>
          <Box>
            {menus &&
              menus.map((menu, i) => (
                <Link
                  to={`${this.props.match.url}/manage-menu/${menu.id}`}
                  key={i}
                >
                  <ListItem>
                    <Title>{menu.name}</Title>
                  </ListItem>
                </Link>
              ))}
          </Box>
        </Box>
        <Box mt={3}>
          <Row p={2} y space="between">
            <Text.title fontSize={4}>Admins</Text.title>
            <Row x y onClick={this.openCreationModal('admin')} cursor="pointer">
              <Text.title size={5} mr={2}>
                +
              </Text.title>
              <Text.title>Create</Text.title>
            </Row>
          </Row>
          <Box>
            {admins &&
              admins.map((admin, i) => (
                <Link
                  to={`${this.props.match.url}/manage-admins/${admin.id}`}
                  key={i}
                >
                  <ListItem>
                    <Title>{admin.id}</Title>
                  </ListItem>
                </Link>
              ))}
          </Box>
        </Box>
        <Box mt={3}>
          <Row p={2} y space="between">
            <Text.title fontSize={4}>Campaigns</Text.title>
            <Row
              x
              y
              onClick={this.openCreationModal('campaign')}
              cursor="pointer"
            >
              <Text.title size={5} mr={2}>
                +
              </Text.title>
              <Text.title>Create</Text.title>
            </Row>
          </Row>
          <Box>
            {campaigns &&
              campaigns.map((campaign, i) => (
                <Link
                  to={`${this.props.match.url}/manage-campaign/${campaign.id}`}
                  key={i}
                >
                  <ListItem>
                    <Title>{campaign.name}</Title>
                  </ListItem>
                </Link>
              ))}
          </Box>
          <Box>
            <DataTransfer
              productionInfo={productionInfo}
              onTransferRequest={this.props.goLive}
            />
          </Box>
        </Box>
      </Box>
    )
  }
})

const Processing = () => (
  <Col x>
    <RingLoader color={s.colors.aqua} />
    <Text.title>Processing</Text.title>
  </Col>
)
const GoLive = props => {
  const [newId, setId] = React.useState('')
  const [isTransferring, setTransferStatus] = React.useState(false)

  const startTransfer = () => {
    setTransferStatus(true)
    props.onTransferRequest(newId).then(() => {
      setId('')
      setTransferStatus(false)
    })
  }

  const isLive = props.productionInfo
    ? !!props.productionInfo.goLiveDate
    : false

  return (
    <Box mt={5}>
      <Row pb={2}>
        <Text.title size={3} mr={1}>
          Status:{' '}
        </Text.title>
        <Text.title size={3} color={isLive ? s.colors.green : s.colors.red}>
          {isLive
            ? `Went live ${dateFns.format(
                props.productionInfo.goLiveDate.toDate(),
                'MM/DD/YY'
              )}`
            : 'Not Live'}
        </Text.title>
      </Row>
      {isLive ? (
        <Row pb={2}>
          <Text.title size={3} mr={1}>
            Production Id:{' '}
          </Text.title>
          <Text.title size={3}>{props.productionInfo.goLiveId}</Text.title>
        </Row>
      ) : (
        undefined
      )}
      <Col
        py={4}
        px={3}
        border={`solid 2px ${s.colors.midnight}`}
        css={`
          box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
        `}
      >
        {isTransferring ? (
          <Processing />
        ) : (
          <Box maxWidth={'300px'} w={300} m="0 auto">
            <InputField
              autoFocus
              label="Live Org Id (e.g. slabpizzaprovo)"
              value={newId}
              onChange={e => setId(e.target.value)}
            />
            {isLive ? (
              <Button.warn onClick={startTransfer}>
                Send Another Copy to Production
              </Button.warn>
            ) : (
              <Button onClick={startTransfer}>Send To Production</Button>
            )}
          </Box>
        )}
      </Col>
    </Box>
  )
}
GoLive.propTypes = {
  onTransferRequest: pt.func.isRequired,
  productionInfo: pt.shape({
    goLiveDate: pt.object,
    goLiveId: pt.string
  })
}

let DataTransfer = GoLive

if (process.env.__PROD__ === 'true') {
  DataTransfer = () => {
    return <Box />
  }
}

export default connect(
  (state, props) => ({
    orgData: selectors.getOrgData(props.match.params.id)(state),
    orgId: props.match.params.id
  }),
  (dispatch, props) => ({
    goLive: newId =>
      console.log('hi', newId) ||
      dispatch(
        goLive({
          orgId: props.match.params.id,
          newId
        })
      ),
    getOrgEntity: id => dispatch(getOrgEntity(id)),
    getLocationEntities: orgId => dispatch(getLocationEntities(orgId)),
    getMenuEntities: orgId => dispatch(getMenuEntities(orgId)),
    getAdminEntities: orgId => dispatch(getAdminEntities(orgId)),
    getCampaignEntities: orgId => dispatch(getCampaignEntities(orgId)),
    createLocation: locationObj =>
      dispatch(createNewLocation(props.match.params.id)(locationObj)),
    createMenu: menuObj =>
      dispatch(createNewMenu(props.match.params.id)(menuObj)),
    onCopyMenuSubmit: menuObj =>
      dispatch(makeMenuCopy(props.match.params.id)(menuObj))
  })
)(ManageOrg)
