import React, { useState } from 'react'
import cc from 'create-react-class'
import pt from 'prop-types'
import { connect } from 'react-redux'
import { RingLoader } from 'react-spinners'
import Save from 'App/shared/Save'
import { Link } from 'react-router-dom'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'

import { Flex, Input, Select, CheckboxGroup, CheckboxOption } from 'boostly-ui'
import {
  Row,
  Col,
  Box,
  Button,
  Text,
  Checkbox,
  settings as s
} from 'boostly-ui2'
import {
  getLocationEntity,
  updateLocationEntity,
  getPickUpScheduleEntities,
  getDeliveryScheduleEntities,
  createSchedule,
  updateSchedule,
  getMenuEntities,
  addGeopoint,
  createCustomStripeAccount
} from './actions'
import {
  getLocationData,
  getPickUpSchedules,
  getDeliverySchedules,
  getMenus
} from './selectors'
import {
  indexToWeekDay,
  createNewDay,
  addAvailability,
  createTimestamp,
  createNewArray
} from './logic'
import { Tab, Tabs, TabPanel } from 'App/shared/TabComponents'
const Title = props => <Text.title size={3} {...props} />

const Label = ({ label, children }) => (
  <Flex pr={2} pb={1} align="center">
    <Text>{label || children}</Text>
  </Flex>
)
const InputField = ({ label, inputRef, width = '100%', ...rest }) => (
  <Flex column py={1} w={width}>
    <Label label={label} />
    <Box>
      <Input {...rest} />
    </Box>
  </Flex>
)

const toMap = array =>
  array.reduce(
    (map, arrayValue, index) => ({ ...map, [index]: arrayValue }),
    {}
  )

const ManageLocation = cc({
  propTypes: { locationData: pt.object, locationId: pt.string },
  getInitialState() {
    return {
      name: '',
      address: { line1: '', zip: '', state: '', city: '' },
      availableMethods: [],
      availableTimings: [],
      pickUpSchedule: null,
      deliverySchedule: null,
      changesWereMade: false,
      defaultSettings: {}
    }
  },
  componentDidMount() {
    this.props.getLocationData()
  },
  componentWillReceiveProps(next) {
    this.setState(() => ({ ...next.locationData }))
  },
  changesWereMade() {
    this.setState({ changesWereMade: true })
  },
  onNameChange({ target }) {
    this.setState(() => ({ name: target.value }))
    this.changesWereMade()
  },
  onAddressChange(key) {
    return ({ target }) => {
      this.setState(prev => ({
        address: { ...prev.address, [key]: target.value }
      }))
      this.changesWereMade()
    }
  },
  onMethodChange(value) {
    this.setState(() => ({ availableMethods: value }))
    this.changesWereMade()
  },
  onTimingChange(value) {
    this.setState(() => ({ availableTimings: value }))
    this.changesWereMade()
  },
  onSave() {
    const {
      name,
      address,
      availableMethods,
      availableTimings,
      menuId,
      defaultSettings
    } = this.state
    this.props.updateLocationData({
      name,
      address,
      availableMethods,
      availableTimings,
      menuId,
      defaultSettings
    })
    this.setState({
      changesWereMade: false
    })
  },
  onDefaultSettingsChange(defaultSettings) {
    this.setState(
      prev => ({
        ...prev,
        defaultSettings: {
          ...prev.defaultSettings, // in case settings are added not from UI
          ...defaultSettings
        }
      }),
      this.changesWereMade
    )
  },
  updateLocationPickUpSchedule(scheduleId) {
    this.props.updateLocationData({ pickUpSchedule: scheduleId })
  },
  updateLocationDeliverySchedule(scheduleId) {
    this.props.updateLocationData({ deliverySchedule: scheduleId })
  },
  onCreateNewScheduleRequest(scheduleName) {
    return test => this.props.updateLocationData({ [scheduleName]: null })
  },
  onMenuSelect({ target }) {
    this.setState(() => ({ menuId: target.value }))
    this.changesWereMade()
  },
  createGeoPoint() {
    const { address } = this.state
    geocodeByAddress(
      `${address.line1}, ${address.city}, ${address.state}, USA`
    ).then(result =>
      getLatLng(result[0]).then(coords => this.props.addGeopoint(coords))
    )
  },
  render() {
    const {
      name,
      address,
      availableTimings,
      availableMethods,
      pickUpSchedule,
      deliverySchedule,
      changesWereMade,
      menuId,
      stripeCustomAccountId
    } = this.state
    const { orgId, locId } = this.props.match.params
    const tabs = [
      'Name & Address',
      'Default Settings',
      'Timing & Method',
      'Pick Up Schedule',
      'Delivery Schedule',
      'Menu',
      'Stripe'
    ]

    return (
      <Box pb={4}>
        <Row p={2} py={3} y height={70} align="center" space="between">
          <Title fontSize={4}>
            Manage {this.props.locationData.name} Details
          </Title>
          <Box w="200px" display={changesWereMade ? 'block' : 'none'}>
            <Button onClick={this.onSave}>Save Changes to DB</Button>
          </Box>
        </Row>
        <Tabs>
          <Row>
            <Col w={200}>
              {tabs.map((title, i) => (
                <Tab key={i}>
                  <Title size={2}>{title}</Title>
                </Tab>
              ))}

              <Link to={`/reporting/${orgId}/${locId}`}>
                <Tab>
                  <Title size={2}>Reporting</Title>
                </Tab>
              </Link>
            </Col>
            <Box w={`calc(100% - 200px)`} px={2}>
              <TabPanel>
                <div>
                  <InputField
                    autoFocus
                    label="Location Name"
                    value={name}
                    onChange={this.onNameChange}
                  />
                  <InputField
                    label="Address Line 1"
                    value={address.line1}
                    onChange={this.onAddressChange('line1')}
                  />
                  <InputField
                    label="City"
                    value={address.city}
                    onChange={this.onAddressChange('city')}
                  />
                  <Flex>
                    <InputField
                      width="55%"
                      label="State"
                      value={address.state}
                      onChange={this.onAddressChange('state')}
                    />
                    <Box w="5%" />
                    <InputField
                      label="Zip"
                      width="40%"
                      value={address.zip}
                      onChange={this.onAddressChange('zip')}
                    />
                  </Flex>
                  {address.geopoint ? (
                    undefined
                  ) : (
                    <Box py={2}>
                      <Button onClick={this.createGeoPoint}>
                        Create Geo Point
                      </Button>
                    </Box>
                  )}
                </div>
              </TabPanel>
              <TabPanel>
                <DefaultSettings
                  onUpdate={this.onDefaultSettingsChange}
                  settings={this.state.defaultSettings}
                />
              </TabPanel>
              <TabPanel>
                <div>
                  <Label label="Select Timings Available" />
                  <CheckboxGroup
                    selectedValue={availableTimings}
                    onChange={this.onTimingChange}
                  >
                    <CheckboxOption value="now">
                      <Text>Now</Text>
                    </CheckboxOption>
                    <CheckboxOption value="later">
                      <Text>Later</Text>
                    </CheckboxOption>
                  </CheckboxGroup>
                  <Box mt={2} />
                  <Label label="Select Methods Available" />
                  <CheckboxGroup
                    selectedValue={availableMethods}
                    onChange={this.onMethodChange}
                  >
                    <CheckboxOption value="pickup">
                      <Text>Pick Up</Text>
                    </CheckboxOption>
                    <Box mt={1} />
                    <CheckboxOption value="delivery">
                      <Text>Delivery</Text>
                    </CheckboxOption>
                    <CheckboxOption value="dinein">
                      <Text>Dine In</Text>
                    </CheckboxOption>
                    <Box mt={1} />
                    <CheckboxOption value="curbside">
                      <Text>Curbside</Text>
                    </CheckboxOption>
                  </CheckboxGroup>
                </div>
              </TabPanel>
              <TabPanel>
                <Scheduling
                  creatingNew={!pickUpSchedule}
                  onCreateNewRequest={this.onCreateNewScheduleRequest(
                    'pickUpSchedule'
                  )}
                  schedule={pickUpSchedule}
                  schedules={this.props.pickUpSchedules}
                  onNewScheduleSelect={this.updateLocationPickUpSchedule}
                  createNewSchedule={this.props.createSchedule('pickup')}
                  updateSchedule={this.props.updateSchedule('pickup')}
                />
              </TabPanel>
              <TabPanel>
                <Scheduling
                  creatingNew={!deliverySchedule}
                  onCreateNewRequest={this.onCreateNewScheduleRequest(
                    'deliverySchedule'
                  )}
                  schedule={deliverySchedule}
                  schedules={this.props.deliverySchedules}
                  onNewScheduleSelect={this.updateLocationDeliverySchedule}
                  createNewSchedule={this.props.createSchedule('delivery')}
                  updateSchedule={this.props.updateSchedule('delivery')}
                />
              </TabPanel>
              <TabPanel>
                <Box w="70%">
                  {this.props.menus.length ? (
                    <Select value={menuId} onChange={this.onMenuSelect}>
                      <option>Pick a Menu</option>
                      {this.props.menus.map((m, i) => (
                        <option value={m.id} key={i}>
                          {m.name}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    <Box>No Menu's Created Yet</Box>
                  )}
                </Box>
              </TabPanel>
              <TabPanel>
                <StripeAccount
                  accountId={stripeCustomAccountId}
                  onCreateRequest={this.props.createCustomStripeAccount}
                />
              </TabPanel>
            </Box>
          </Row>
        </Tabs>
      </Box>
    )
  }
})

const StripeAccount = props => {
  const [ipAddress, updateIp] = useState('')
  const [isLoading, setLoadingState] = useState(false)

  const createCustomStripeAccount = () => {
    if (!ipAddress) return

    setLoadingState(true)
    props.onCreateRequest(ipAddress).then(() => {
      setLoadingState(false)
    })
  }

  if (isLoading) {
    return (
      <Row x>
        <RingLoader color={s.colors.aqua} />
      </Row>
    )
  }

  return props.accountId ? (
    <Box>
      <Text.title>✅ Stripe Account</Text.title>
      <Box>
        <Text>Custom Account Id: {props.accountId}</Text>
      </Box>
    </Box>
  ) : (
    <Box maxWidth={400}>
      <InputField
        label="IP Address"
        value={ipAddress}
        onChange={e => updateIp(e.target.value)}
      />
      <Box py={2} px={1}>
        <Button onClick={createCustomStripeAccount}>
          Create Stripe Account
        </Button>
      </Box>
    </Box>
  )
}

StripeAccount.propTypes = {
  accountId: pt.string,
  onCreateRequest: pt.func.isRequired
}

const maybeConvertToNum = val => {
  if (val !== '' && typeof val === 'string') {
    // filter bools
    return typeof Number(val) === 'number' ? Number(val) : val
  } else {
    return val
  }
}
const useInputs = value => {
  const [state, updateState] = useState(value)
  return [
    state,
    key => input => {
      const val = input.target ? input.target.value : input
      updateState({
        ...state,
        [key]: maybeConvertToNum(val)
      })
    }
  ]
}

const CheckboxWithLabel = ({ label, isChecked, onSelect }) => (
  <Row onClick={onSelect}>
    <Checkbox checked={isChecked} />
    <Text ml={2}>{label}</Text>
  </Row>
)
CheckboxWithLabel.propTypes = {
  isChecked: pt.bool,
  onSelect: pt.func,
  label: pt.string
}
const VertSpacing = () => <Box my={2} />
const HoriSpacing = () => <Box mx={2} />
const DefaultSettings = ({ settings, onUpdate }) => {
  const [changesWereMade, setChangeStatus] = useState(false)
  const [state, updateInput] = useInputs({
    acceptTips: settings.acceptTips || true,
    alertFutureOrdersNow: settings.alertFutureOrdersNow || false,
    deliveryOrderMinimum: settings.deliveryOrderMinimum || 10,
    orderMinimum: settings.orderMinimum || 0,
    deliveryTime: settings.deliveryTime || 15,
    prepTime: settings.prepTime || 20,
    salesTax: settings.salesTax || 0.0685,
    supportPromotions: settings.supportPromotions || true
  })

  const update = key => val => {
    updateInput(key)(val)
    setChangeStatus(true)
  }

  const onSave = () => {
    onUpdate(state)
    setChangeStatus(false)
  }
  return (
    <Box>
      <Row space="between" pb={2} h={50} y>
        <Title my={2}> Default Settings Yo</Title>
        <Box w="200px" display={changesWereMade ? 'block' : 'none'}>
          <Button onClick={onSave}>Save Changes Locally</Button>
        </Box>
      </Row>
      <Col>
        <CheckboxWithLabel
          label="Accept Tips"
          isChecked={state.acceptTips}
          onSelect={() => update('acceptTips')(!state.acceptTips)}
        />
        <VertSpacing />
        <CheckboxWithLabel
          label="Alert Future Orders Now"
          isChecked={state.alertFutureOrdersNow}
          onSelect={() =>
            update('alertFutureOrdersNow')(!state.alertFutureOrdersNow)
          }
        />
        <VertSpacing />
        <CheckboxWithLabel
          label="Support Promotions"
          isChecked={state.supportPromotions}
          onSelect={() => update('supportPromotions')(!state.supportPromotions)}
        />
      </Col>
      <VertSpacing />
      <InputField
        label="Sales Tax (decimal; 0.0685 = 6.85%)"
        value={state.salesTax}
        type="number"
        onChange={update('salesTax')}
      />
      <Row>
        <InputField
          label="Order Minumum"
          width="47%"
          value={state.orderMinimum}
          type="number"
          onChange={update('orderMinimum')}
        />
        <HoriSpacing />
        <InputField
          label="Delivery Minumum"
          width="47%"
          value={state.deliveryOrderMinimum}
          type="number"
          onChange={update('deliveryOrderMinimum')}
        />
      </Row>
      <InputField
        label="Prep Time (minutes)"
        value={state.prepTime}
        type="number"
        onChange={update('prepTime')}
      />
      <InputField
        label="Delivery Time (minutes)"
        value={state.deliveryTime}
        type="number"
        onChange={update('deliveryTime')}
      />
    </Box>
  )
}

DefaultSettings.propTypes = {
  settings: pt.object,
  onUpdate: pt.func
}

export default connect(
  (state, props) => ({
    locationData: getLocationData(props.match.params.locId)(state) || {},
    pickUpSchedules: getPickUpSchedules(props.match.params.orgId)(state) || [],
    deliverySchedules:
      getDeliverySchedules(props.match.params.orgId)(state) || [],
    menus: getMenus(props.match.params.orgId)(state)
  }),
  (dispatch, props) => {
    const docIds = props.match.params
    return {
      createCustomStripeAccount: ipAddress =>
        dispatch(
          createCustomStripeAccount({
            ipAddress,
            ...docIds
          })
        ),
      getLocationData: () => {
        dispatch(getMenuEntities(docIds))
        dispatch(getLocationEntity(docIds))
        dispatch(getPickUpScheduleEntities(docIds))
        dispatch(getDeliveryScheduleEntities(docIds))
      },
      updateLocationData: locData =>
        dispatch(updateLocationEntity({ ...docIds, locData })),
      addGeopoint: coords => addGeopoint(docIds)(coords),
      createSchedule: scheduleType => newSchedule =>
        dispatch(createSchedule(scheduleType)(docIds)(newSchedule)),
      updateSchedule: scheduleType => newSchedule =>
        dispatch(updateSchedule(scheduleType)(docIds)(newSchedule))
    }
  }
)(ManageLocation)

const meridiems = ['am', 'pm']
const hours = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
const minutes = ['00', 15, 30, 45]
const timeObj = { hour: 12, minute: 0, meridiem: 'AM' }
const defaultSchedule = {
  models: createNewArray(7, { startTime: timeObj, endTime: timeObj })
}
const Scheduling = cc({
  propTypes: { schedule: pt.object },
  getInitialState() {
    return {
      schedule: this.props.schedule || defaultSchedule,
      changesWereMade: false,
      editingExisting: false
    }
  },
  componentWillReceiveProps(next) {
    this.setState(prev => ({ schedule: next.schedule || defaultSchedule }))
  },
  onValueUpdate(dayIndex, isStartTime, propValueUpdated) {
    return ({ target }) => {
      const { schedule } = this.state
      const models = schedule.models
      const periodName = isStartTime ? 'startTime' : 'endTime'
      const day = models[dayIndex]
      const updatedDay = {
        ...day,
        [periodName]: { ...day[periodName], [propValueUpdated]: target.value }
      }

      this.setState({
        schedule: {
          ...schedule,
          models: [
            ...models.slice(0, dayIndex),
            updatedDay,
            ...models.slice(dayIndex + 1, models.length)
          ]
        },
        changesWereMade: true,
        editingExisting: !this.props.creatingNew
      })
    }
  },
  onScheduleSelect({ target }) {
    if (target.value === 'new') {
      this.props.onCreateNewRequest()
    } else {
      this.props.onNewScheduleSelect(target.value)
    }
  },
  onNameUpdate({ target }) {
    this.setState(prev => ({
      schedule: { ...prev.schedule, name: target.value },
      changesWereMade: true
    }))
  },
  saveHours() {
    if (!this.state.schedule.name) {
      return window.alert('Schedule Needs a Name')
    }
    const action = this.props.creatingNew
      ? this.props.createNewSchedule
      : this.props.updateSchedule

    const blankDay = createNewDay()

    action({
      ...this.state.schedule,
      models: toMap(
        this.state.schedule.models.map(day =>
          addAvailability({
            day: blankDay,
            startStamp: createTimestamp(day.startTime),
            endStamp: createTimestamp(day.endTime)
          })
        )
      )
    })
    this.setState(prev => ({ changesWereMade: false }))
  },
  render() {
    const { schedules } = this.props
    return (
      <Box>
        <Flex justify="space-between" px={2} py={2}>
          <Box w="70%">
            {schedules.length ? (
              <Select
                value={this.state.schedule.id}
                onChange={this.onScheduleSelect}
              >
                <option value="new">Create New Schedule</option>
                {schedules.map((c, i) => (
                  <option value={c.id} key={i}>
                    {c.name}
                  </option>
                ))}
              </Select>
            ) : (
              <Box height="40px" />
            )}
          </Box>
          {(this.props.creatingNew || this.state.editingExisting) &&
          this.state.changesWereMade ? (
            <Save size="30px" onClick={this.saveHours} />
          ) : (
            <Box height="30px" />
          )}
        </Flex>
        <Box>
          <InputField
            label="Schedule Name"
            onChange={this.onNameUpdate}
            value={
              this.state.schedule.id || this.state.schedule.name
                ? this.state.schedule.name
                : ''
            }
          />
        </Box>
        {this.state.schedule.models.map((day, dayIndex) => (
          <Box
            key={dayIndex}
            mb={2}
            bg={dayIndex % 2 ? s.colors.lightBase : s.colors.lightBaseHighlight}
            p={1}
          >
            <Box>
              <Label>
                <Title fontSize={3}>{indexToWeekDay[dayIndex]}</Title>
              </Label>
            </Box>
            <Flex wrap justify="space-between">
              {['startTime', 'endTime'].map((label, timeIndex) => (
                <Box
                  key={timeIndex}
                  w={[1, 1 / 2]}
                  pr={[0, timeIndex % 2 ? 0 : 2]}
                  pl={[0, timeIndex % 2 ? 2 : 0]}
                  mt={[timeIndex > 0 ? 2 : 0, 0]}
                >
                  <Label>
                    {label === 'startTime' ? 'Start Time' : 'End Time'}
                  </Label>
                  <Flex mt={1}>
                    {[
                      { values: hours, valueName: 'hour' },
                      { values: minutes, valueName: 'minute' },
                      { values: meridiems, valueName: 'meridiem' }
                    ].map((props, i) => (
                      <Box w="33%" ml={i > 0 ? 1 : 0}>
                        <Select
                          value={
                            this.state.schedule.models[dayIndex][label][
                              props.valueName
                            ]
                          }
                          onChange={this.onValueUpdate(
                            dayIndex,
                            label === 'startTime',
                            props.valueName
                          )}
                        >
                          {props.values.map((o, key) => (
                            <option key={key} value={o}>
                              {o}
                            </option>
                          ))}
                        </Select>
                      </Box>
                    ))}
                  </Flex>
                </Box>
              ))}
            </Flex>
          </Box>
        ))}
      </Box>
    )
  }
})
