import React from 'react'
import cc from 'create-react-class'
import pt from 'prop-types'
import { format, differenceInSeconds, isToday } from 'date-fns'
import { Flex, Box, Text, Title, settings as s } from 'boostly-ui'
import { toPrice, formatPhone, pad } from 'utils/rendering'
import { Div } from 'glamorous'
import CaretIcon from 'App/shared/CaretIcon'

const headWidth = 122

const CollapseHead = ({ isOpen, onToggle, children }) => (
  <Box
    bg={s.colors.base}
    cursor="pointer"
    transition=".2s"
    pr={2}
    onClick={onToggle}
    border={`solid 1px ${s.colors.baseBorder}`}
  >
    <Flex justify="space-between" align="center">
      <Box>{children}</Box>
      <Box>
        <CaretIcon direction={isOpen ? 'down' : 'right'} />
      </Box>
    </Flex>
  </Box>
)

const CollapseBody = ({ isOpen, children }) => (
  <Box
    maxHeight={isOpen ? '50000px' : 0}
    overflow="hidden"
    transition=".25s ease"
  >
    {children}
  </Box>
)

const Collapse = cc({
  propTypes: { children: pt.func },
  getInitialState() {
    return { open: this.props.open || false }
  },
  toggleOpen() {
    this.setState(() => ({ open: !this.state.open }))
  },
  render() {
    const { open } = this.state
    const { children } = this.props
    return (
      <Box width="100%">
        {children({
          isOpen: open,
          onToggle: this.toggleOpen,
          CollapseHead,
          CollapseBody
        })}
      </Box>
    )
  }
})
const OrderInstructions = ({ copy }) => (
  <Text is="p" fontSize="12px" fontStyle="italic" pr={4} pt={'4px'}>
    <span style={{ fontWeight: 'bold' }}>Instructions:</span> {copy}
  </Text>
)
/**
 * 1. 15px, 16px ml box is same as CloseAction to ensure alignment
 * it's ugly and not very DRY but it works
 */
const SelectionLineItem = ({ name, cost, inverted }) => (
  <Flex justify="space-between">
    <Text fontSize="12px">
      {inverted ? (
        <Title>
          <Box is="span" color={s.colors.primaryCta}>
            No
          </Box>{' '}
        </Title>
      ) : (
        ''
      )}
      {name}
    </Text>
    <Flex>
      <Text fontSize="12px">{inverted ? '' : cost ? toPrice(cost) : ''}</Text>
    </Flex>
  </Flex>
)

const methodTypeToLabel = {
  PICK_UP: 'Pick Up',
  DELIVERY: 'Delivery',
  CURBSIDE: 'Curbside',
  DINE_IN: 'Dine In',
  undefined: 'Missing'
}

const methodTypeToEmoji = {
  PICK_UP:
    'https://firebasestorage.googleapis.com/v0/b/boostly-live.appspot.com/o/assets%2Femojis%2Ffemale-weightlifter.png?alt=media&token=2c69bf3d-b38b-4f09-9538-c0e713e49724',
  DELIVERY:
    'https://firebasestorage.googleapis.com/v0/b/boostly-live.appspot.com/o/assets%2Femojis%2Fautomobile.png?alt=media&token=25fe9ba2-b550-4c16-94bf-867c656c22d7',
  DINE_IN:
    'https://firebasestorage.googleapis.com/v0/b/boostly-live.appspot.com/o/assets%2Femojis%2F%F0%9F%8D%BD.png?alt=media&token=7c94d2ea-8798-42b3-9bd4-a50587306187',
  CURBSIDE:
    'https://firebasestorage.googleapis.com/v0/b/boostly-live.appspot.com/o/assets%2Femojis%2F%F0%9F%9A%99.png?alt=media&token=0efdd1c5-b1ed-4a5f-8c5e-c946b55f4ff6'
}

const OrderListing = cc({
  propTypes: {
    details: pt.shape({
      fulfillBy: pt.oneOfType([pt.string, pt.object]),
      status: pt.string,
      consumer: pt.object,
      createdAt: pt.oneOfType([pt.string, pt.object]),
      cost: pt.shape({
        total: pt.oneOfType([pt.string, pt.number]),
        subtotal: pt.oneOfType([pt.string, pt.number]),
        tax: pt.oneOfType([pt.string, pt.number]),
        tip: pt.oneOfType([pt.string, pt.number])
      })
    })
  },
  statusToLabel: {
    awaitingApproval: 'Awaiting Approval',
    inProgress: 'In Progress',
    fulfilled: 'Fulfilled',
    declined: 'Declined'
  },
  formatExpectedTime(date) {
    return format(date, `${isToday(date) ? '' : 'ddd, '}h:mm A`)
  },
  testDate: new Date(),
  renderHeader({ status, cost, method = {}, consumer, createdAt, fulfillBy }) {
    return (
      <Flex>
        <Flex column align="center" justify="center" w={`${headWidth}px`} p={1}>
          <Flex>
            <Box
              is="img"
              pr="3px"
              w="20px"
              height="20px"
              src={methodTypeToEmoji[method.type]}
            />
            <Title>{methodTypeToLabel[method.type]}</Title>
          </Flex>
          <Text>{toPrice(cost.total)}</Text>
          <Countdown until={fulfillBy.toDate()} />
          <Text>({this.formatExpectedTime(fulfillBy.toDate())})</Text>
        </Flex>
        <Box border={`solid 1px ${s.colors.baseHighlight}`} />
        <Flex column justify="center" pl={2} py={1}>
          <Title>
            {consumer.firstName} {consumer.lastName}
          </Title>
          <Title>
            <Text>Status: </Text>
            <Box
              is="span"
              color={
                status === 'fulfilled' ? s.colors.success : s.colors.primaryCta
              }
            >
              {this.statusToLabel[status]}
            </Box>
          </Title>
          <Text>
            Submitted:{' '}
            <Title>{format(new Date(createdAt.toDate()), 'hh:mm A')}</Title>
          </Text>
          <Text>
            Expected:{' '}
            <Title>{this.formatExpectedTime(fulfillBy.toDate())}</Title>
          </Text>
          <Text>
            Phone:{' '}
            <Title>
              {consumer.phoneNumber ? formatPhone(consumer.phoneNumber) : ''}
            </Title>
          </Text>
          {method.data && method.data.address ? (
            <Text>
              Address:{' '}
              <Title>
                {method.data.address}{' '}
                {method.data.unit ? `Unit: ${method.data.unit}` : ''}
              </Title>
            </Text>
          ) : (
            undefined
          )}
          {method.data && method.data.make ? (
            <Text>
              Car Details:{' '}
              <Title>
                {`${method.data.color} ${method.data.make} ${
                  method.data.model
                }`}
              </Title>
            </Text>
          ) : (
            undefined
          )}
        </Flex>
      </Flex>
    )
  },
  renderItems(items) {
    return items.map((item, i) => (
      <Div
        display="flex"
        key={`${item.id}-${i}`}
        backgroundColor={s.colors.lightBaseHighlight}
        border={`solid 1px ${s.colors.base}`}
      >
        <Flex
          w={`${headWidth}px`}
          py={2}
          justify="center"
          fontWeight="bold"
          fontSize="1.3em"
        >
          {item.quantity}
        </Flex>
        <Box border={`solid 1px ${s.colors.baseHighlight}`} />
        <Flex column flexGrow={1} px={2} py={1}>
          <Flex justify="space-between" flexGrow={1}>
            <Flex column>
              <Title>{item.name}</Title>
              <Text>{item.servingPortion.name}</Text>
            </Flex>
            <Flex column justify="flex-end">
              {toPrice(item.servingPortion.price)}
            </Flex>
          </Flex>
          <Flex width="100%" column>
            {(item.selectionSets || []).map((set, id) =>
              set.selections.length ? (
                <Box key={set.id} pt="2px">
                  <Title fontSize="12px" fontStyle="italic">
                    {set.name}
                  </Title>
                  <Box pt={'2px'}>
                    {set.selections.map((sel, i) => (
                      <Box key={i} pt="2px">
                        <SelectionLineItem
                          {...sel}
                          inverted={set.optionsAreDefaults}
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
              ) : (
                undefined
              )
            )}
            {item.instructions && (
              <OrderInstructions copy={item.instructions} />
            )}
          </Flex>
        </Flex>
      </Div>
    ))
  },
  render() {
    const { details, isComplete, onCheckBoxSelect, expanded } = this.props
    return (
      <Collapse open={expanded}>
        {({ CollapseHead, CollapseBody, ...props }) => (
          <Box>
            <Flex>
              {onCheckBoxSelect ? (
                details.status !== 'declined' ? (
                  <CompletionBox
                    onClick={onCheckBoxSelect}
                    isComplete={isComplete}
                  />
                ) : (
                  <Box w={`${headWidth}px`} bg={s.colors.base} />
                )
              ) : (
                undefined
              )}
              <Box flexGrow={1}>
                <CollapseHead {...props}>
                  {this.renderHeader(details)}
                </CollapseHead>
              </Box>
            </Flex>
            <CollapseBody {...props}>
              <Box my={1}>{this.renderItems(details.items)}</Box>
              <Flex
                mb={1}
                bg={s.colors.lightBaseHighlight}
                border={`solid 1px ${s.colors.base}`}
              >
                <Box w={`${headWidth}px`} />
                <Box border={`solid 1px ${s.colors.base}`} />
                <Box flexGrow={1}>
                  {(details.fees || []).map((fee, i) => (
                    <Flex justify="space-between" py="4px" px={2}>
                      <Text>{fee.name}</Text>
                      <Text>{toPrice(fee.value)}</Text>
                    </Flex>
                  ))}
                  <Flex justify="space-between" py="4px" px={2}>
                    <Text>Subtotal</Text>
                    <Text>{toPrice(details.cost.subtotal)}</Text>
                  </Flex>
                  {details.cost.discount > 0 ? (
                    <Flex justify="space-between" py="4px" px={2}>
                      <Text>Discount</Text>
                      <Text>({toPrice(details.cost.discount)})</Text>
                    </Flex>
                  ) : (
                    undefined
                  )}
                  <Flex justify="space-between" py="4px" px={2}>
                    <Text>Sales Tax</Text>
                    <Text>{toPrice(details.cost.tax)}</Text>
                  </Flex>
                  {details.cost.tip > 0 ? (
                    <Flex justify="space-between" py="4px" px={2}>
                      <Text>Tip</Text>
                      <Text>{toPrice(details.cost.tip)}</Text>
                    </Flex>
                  ) : (
                    undefined
                  )}
                  <Flex bg={s.colors.success}>
                    <Flex justify="space-between" flexGrow={1} px={2} py={1}>
                      <Title>Total</Title>
                      <Title>{toPrice(details.cost.total)}</Title>
                    </Flex>
                  </Flex>
                </Box>
              </Flex>
            </CollapseBody>
          </Box>
        )}
      </Collapse>
    )
  }
})

export default OrderListing

const Countdown = cc({
  countdown: null,
  propTypes: { until: pt.object },
  getInitialState() {
    return { secondsLeft: differenceInSeconds(this.props.until, new Date()) }
  },
  componentDidMount() {
    this.updateTime()
  },
  componentWillUnmount() {
    window.clearTimeout(this.countdown)
  },
  updateTime() {
    const secondsLeft = differenceInSeconds(this.props.until, new Date())
    if (secondsLeft <= 0) {
      this.setState(prev => ({ secondsLeft: 0 }))
    } else {
      this.setState(prev => ({ secondsLeft }))
      this.countdown = window.setTimeout(this.updateTime, 1000)
    }
  },
  format(secondsLeft) {
    const hours = Math.floor(secondsLeft / 3600)
    const minutes = pad(Math.floor((secondsLeft % 3600) / 60), 2)
    const seconds = pad(secondsLeft % 60, 2)
    return `${hours ? hours + ':' : ''}${minutes}:${seconds}`
  },
  render() {
    const sl = this.state.secondsLeft
    return (
      <Text fontSize={4} success={sl > 120} error={sl <= 120}>
        {this.format(this.state.secondsLeft)}
      </Text>
    )
  }
})

const CompletionBox = ({ isComplete, onClick }) => (
  <Flex
    border={`solid 1px ${s.colors.baseHighlight}`}
    onClick={onClick}
    cursor="pointer"
    max-width={`${headWidth}px`}
    w="100%"
    bg={s.colors.base}
    align="center"
    justify="center"
  >
    <Flex w="74px" height="74px" justify="center" align="center" bg="white">
      {isComplete && (
        <Box
          is="svg"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="40px"
          viewBox="0 0 512 512"
        >
          <polygon
            fill={s.colors.success}
            points="202.624,478.016 0,291.36 70.512,214.8 191.968,326.656 431.44,33.984 512,99.904 "
          />
        </Box>
      )}
    </Flex>
  </Flex>
)
