import React from 'react'
import { connect } from 'react-redux'
import cc from 'create-react-class'
import pt from 'prop-types'
import { List } from 'react-virtualized'
import { format, isAfter, isBefore, getDate, getMonth, getYear } from 'date-fns'
import {
  Modal,
  Flex,
  Title,
  Select,
  Input,
  Textarea,
  Button,
  LoadingOverlay,
  settings as s
} from 'boostly-ui'
import { Text, Row, Col, Box, settings as s2 } from 'boostly-ui2'
import { getOrgEntities, loadGlobalSettings } from 'App/state'
import EditIcon from 'App/shared/EditIcon'
import CancelIcon from 'App/shared/CancelIcon'
import {
  loadOrgLocations,
  loadAllOrders,
  loadOrderEntities,
  loadConsumerBatch,
  loadReviews,
  select,
  addOrderAdjustment,
  cancelOrder
} from './state'

const toPrice = num => `$${Number(num).toFixed(2)}`

let dayArray = []
let day = 1
while (day <= 31) {
  dayArray.push(day)
  day++
}

const moIndexToName = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]
function precisionRound(number, precision) {
  var factor = Math.pow(10, precision)
  return Math.round(number * factor) / factor
}
const getTipsMade = orders =>
  orders.reduce((total, order) => total + order.cost.tip, 0)
const getTotalMade = orders =>
  orders.reduce((total, order) => total + order.cost.total, 0)
const getSubtotalMade = orders =>
  orders.reduce((total, order) => total + order.cost.subtotal, 0)
const getTaxTotal = orders =>
  orders.reduce((total, order) => total + order.cost.tax, 0)
const getMerchantFeeAmount = orders =>
  orders.reduce((total, { cost }) => {
    const percentAmount = cost.total * 0.0295
    return precisionRound(percentAmount + 0.3, 2) + total
  }, 0)
const getAdjustmentAmount = orders =>
  orders.reduce((total, { adjustmentTotal }) => {
    return adjustmentTotal ? total + adjustmentTotal : total
  }, 0)

const OrderAdjustmentModal = connect(
  ({ settings, entities }, props) => ({
    order: entities.orders[props.orderId],
    adjustmentOptions: settings.orderAdjustments || {}
  }),
  dispatch => ({
    dispatch,
    loadAdjustmentOptions: () => dispatch(loadGlobalSettings())
  })
)(
  cc({
    initialState: {
      amount: 0,
      type: '',
      reason: '',
      reasonType: '',
      errors: [],
      isProcessing: false
    },
    getInitialState() {
      return this.initialState
    },
    componentDidMount() {
      this.props.loadAdjustmentOptions()
    },
    change(key) {
      return ({ target }) => {
        this.setState(prev => ({ [key]: target.value }))
      }
    },
    onSubmit(e) {
      e.preventDefault()
      const { amount, type, reason, reasonType } = this.state
      let errors = []
      if (amount > this.calcChargeAmount(this.props.order)) {
        errors.push(
          `You've entered an amount greater than what has been charged`
        )
      } else if (!type) {
        errors.push(`Adjustment type is required`)
      }

      if (errors.length) {
        this.setState(prev => ({ errors }))
        return
      }
      this.setState(prev => ({ isProcessing: true }))
      this.props
        .dispatch(
          addOrderAdjustment({
            orderId: this.props.orderId,
            amount,
            type,
            reason,
            reasonType
          })
        )
        .then(({ code, message }) => {
          if (code === 400) {
            errors.push(message)
            this.setState(prev => ({ errors }))
          } else {
            this.setState(prev => this.initialState)
            this.props.onRequestClose()
          }
        })
    },
    calcChargeAmount({ cost, adjustmentTotal }) {
      return adjustmentTotal ? cost.total - adjustmentTotal : cost.total
    },
    render() {
      const { order, isOpen, onRequestClose, adjustmentOptions } = this.props
      return (
        <Modal
          heading="Add Adjustment"
          isOpen={isOpen}
          onRequestClose={onRequestClose}
        >
          {this.state.isProcessing && (
            <LoadingOverlay description="Processing" />
          )}
          <Flex column align="center" justify="center" maxWidth="300px">
            <Title>
              {`Amount Charged: ${order &&
                toPrice(this.calcChargeAmount(order))}`}
            </Title>
            {this.state.errors.map(e => (
              <Box p={2} textAlign="center">
                <Text error>{e}</Text>
              </Box>
            ))}
          </Flex>
          <Box p={2} is="form" onSubmit={this.onSubmit}>
            <Box py={1}>
              <Title>Adjustment Type</Title>
              <Select value={this.state.type} onChange={this.change('type')}>
                <option>Select Adjustment Type</option>
                {(adjustmentOptions.types || []).map((type, i) => (
                  <option value={type} key={i}>
                    {type}
                  </option>
                ))}
              </Select>
            </Box>
            <Box py={1}>
              <Title>Amount (in dollars)</Title>
              <Input
                value={this.state.amount}
                onChange={this.change('amount')}
              />
            </Box>
            <Box py={1}>
              <Title>Reason Type</Title>
              <Select
                value={this.state.reasonType}
                onChange={this.change('reasonType')}
              >
                <option>Select Reason Type</option>
                {(adjustmentOptions.reasonTypes || []).map((type, i) => (
                  <option value={type} key={i}>
                    {type}
                  </option>
                ))}
              </Select>
            </Box>
            <Box py={1}>
              <Title>Reason</Title>
              <Textarea
                value={this.state.reason}
                onChange={this.change('reason')}
              />
            </Box>
            <Button type="submit">Submit Adjustment</Button>
          </Box>
        </Modal>
      )
    }
  })
)

const OrderCancelModal = connect(
  ({ settings, entities }, props) => ({
    order: entities.orders[props.orderId]
  }),
  dispatch => ({
    dispatch,
    cancelOrder: orderId => dispatch(cancelOrder(orderId))
  })
)(
  cc({
    initialState: { reason: '', errors: [], isProcessing: false },
    getInitialState() {
      return this.initialState
    },
    change(key) {
      return ({ target }) => {
        this.setState(prev => ({ [key]: target.value }))
      }
    },
    onSubmit(e) {
      e.preventDefault()
      const { reason } = this.state
      let errors = []

      if (errors.length) {
        this.setState(prev => ({ errors }))
        return
      }
      this.setState(prev => ({ isProcessing: true }))
      this.props
        .cancelOrder({ orderId: this.props.orderId, reason })
        .then(({ payload }) => {
          if (payload.code === 400) {
            errors.push(payload.message)
            this.setState(prev => ({ errors }))
          } else {
            this.setState(prev => this.initialState)
            this.props.onRequestClose()
          }
        })
    },
    render() {
      const { order, isOpen, onRequestClose } = this.props
      return (
        <Modal
          heading="Cancel Order"
          isOpen={isOpen}
          onRequestClose={onRequestClose}
        >
          {this.state.isProcessing && (
            <LoadingOverlay description="Processing" />
          )}
          <Flex column align="center" justify="center" maxWidth="300px">
            <Title>
              {`Amount to be refunded: ${order && toPrice(order.cost.total)}`}
            </Title>
            {this.state.errors.map(e => (
              <Box p={2} textAlign="center">
                <Text error>{e}</Text>
              </Box>
            ))}
          </Flex>
          <Box p={2} is="form" onSubmit={this.onSubmit}>
            <Box py={1}>
              <Title>Reason (Optional)</Title>
              <Textarea
                value={this.state.reason}
                onChange={this.change('reason')}
              />
            </Box>
            <Button type="submit">Confirm Cancel Order</Button>
          </Box>
        </Modal>
      )
    }
  })
)

const typeToTiming = { NOW: 'Order Now', LATER: 'Order Ahead' }
const Reporting = cc({
  propTypes: {
    match: pt.object.isRequired,
    orders: pt.array,
    orgList: pt.array
  },
  getInitialState() {
    return {
      startDay: getDate(new Date()),
      startMonth: getMonth(new Date()) + 1,
      orderToAdjust: '',
      startYear: getYear(new Date()),
      endYear: getYear(new Date())
    }
  },
  componentDidMount() {
    this.props.loadOrgs()
  },
  componentWillReceiveProps(next) {
    const consumerIds = next.orders
      .filter(order => typeof order.consumer === 'string')
      .map(order => order.consumer)
    this.props.loadConsumers(consumerIds)
  },
  updateOrg({ target }) {
    const orgId = target.value

    this.props.loadOrgReviews(orgId)
    this.props.loadOrgLocations(orgId)
    this.props.loadOrders(orgId)
    this.setState(prev => ({ selectedOrg: orgId }))
  },
  loadOrders(orgId) {
    this.props.loadOrgOrders({
      orgId: orgId || this.state.selectedOrg,
      startDate: this.getStartDate()
    })
  },
  change(key) {
    return ({ target }) => {
      this.setState(
        prev => ({ [key]: target.value }),
        () => {
          if (
            key === 'startDay' ||
            key === 'startMonth' ||
            key === 'startYear'
          ) {
            this.loadOrders()
          }
        }
      )
    }
  },
  makeDate({ day, month, year }) {
    if (day && month) {
      return new Date(year, month - 1, day)
    }
  },
  getStartDate() {
    const { startDay, startMonth, startYear } = this.state
    return startDay && startMonth
      ? this.makeDate({ day: startDay, month: startMonth, year: startYear })
      : undefined
  },
  getEndDate() {
    const { endDay, endMonth, endYear } = this.state
    return endDay && endMonth
      ? this.makeDate({ day: endDay, month: endMonth, year: endYear })
      : undefined
  },
  getOrdersInQuestion() {
    const { selectedOrg, selectedLoc } = this.state
    let oiq = this.props.orders
    const startDate = this.getStartDate()
    const endDate = this.getEndDate()
    if (startDate) {
      oiq = oiq.filter(o => isAfter(o.createdAt.toDate(), startDate))
    }
    if (endDate) {
      oiq = oiq.filter(o => isBefore(o.createdAt.toDate(), endDate))
    }
    if (selectedOrg) {
      oiq = oiq.filter(o => o.org === selectedOrg)
      if (selectedLoc) {
        return oiq.filter(o => o.location === selectedLoc)
      }
      return oiq
    }
    return oiq
  },
  onCancelRequest(orderId) {
    this.setState(prev => ({ orderToCancel: orderId }))
  },
  onCancelClose() {
    this.setState(prev => ({ orderToCancel: '' }))
  },
  onAdjustmentRequest(orderId) {
    this.setState(prev => ({ orderToAdjust: orderId }))
  },
  onAdjustmentClose() {
    this.setState(prev => ({ orderToAdjust: '' }))
  },
  render() {
    const { selectedOrg, orderToAdjust, orderToCancel } = this.state
    const ordersInQuestion = this.getOrdersInQuestion()
    const ordersToCalculate = ordersInQuestion.filter(o => {
      if (
        o.status === 'declined' ||
        o.status === 'awaitingTransaction' ||
        o.status === 'awaitingValidation' ||
        o.status === 'cancelled'
      ) {
        return false
      }
      return true
    })
    const locations = selectedOrg ? this.props.locationsByOrg[selectedOrg] : ''
    const totalMade = getTotalMade(ordersToCalculate)
    const merchantFees = getMerchantFeeAmount(ordersToCalculate)
    const adjustments = getAdjustmentAmount(ordersToCalculate)
    const netDeposit = totalMade - merchantFees - adjustments
    return (
      <Box>
        <OrderAdjustmentModal
          orderId={orderToAdjust}
          onRequestClose={this.onAdjustmentClose}
          isOpen={!!orderToAdjust}
        />
        <OrderCancelModal
          orderId={orderToCancel}
          onRequestClose={this.onCancelClose}
          isOpen={!!orderToCancel}
        />
        <Row>
          <Box p={1}>
            <Box p={2}>
              <Text.title size={2}>Stats</Text.title>
            </Box>
            {[
              { label: 'Orders', value: ordersInQuestion.length },

              {
                label: 'Successful Orders',
                value: ordersToCalculate.length
              },
              {
                label: 'Subtotal',
                value: toPrice(getSubtotalMade(ordersToCalculate))
              },
              { label: 'Tax', value: toPrice(getTaxTotal(ordersToCalculate)) },
              { label: 'Tips', value: toPrice(getTipsMade(ordersToCalculate)) },
              { label: 'Total Made', value: toPrice(totalMade) },
              {
                label: 'Merchant Fees',
                value: '(' + toPrice(merchantFees) + ')'
              },
              { label: 'Adjustments', value: '(' + toPrice(adjustments) + ')' },
              { label: 'Net Deposit', value: toPrice(netDeposit) },

              {
                label: 'Average Order Size',
                value: ordersToCalculate.length
                  ? toPrice(
                      getTotalMade(ordersToCalculate) / ordersToCalculate.length
                    )
                  : toPrice(0)
              }
            ].map((tuple, i) => (
              <Pair key={i}>
                <span>{tuple.label}:</span>
                <Text.title>{tuple.value}</Text.title>
              </Pair>
            ))}
          </Box>

          <Col p={1}>
            <Box p={2}>
              <Text.title size={2}>Filters</Text.title>
            </Box>
            <Box>
              <Select
                value={this.state.selectedOrg}
                onChange={this.updateOrg}
                label="Orgs"
              >
                <option>Select an Org</option>
                {this.props.orgs.map((org, i) => (
                  <option value={org.id} defaultValue={''} key={i}>
                    {org.name}
                  </option>
                ))}
              </Select>
              <Box p={2} />
              {locations && (
                <Select
                  value={this.state.selectedLoc}
                  onChange={this.change('selectedLoc')}
                  label="Locations"
                >
                  <option>Select a Location</option>
                  {locations.map((loc, i) => (
                    <option value={loc.id} defaultValue={''} key={i}>
                      {loc.name}
                    </option>
                  ))}
                </Select>
              )}
            </Box>
            <Box p={1}>
              <Box p={1}>
                <Text.title>Start Date</Text.title>
              </Box>
              <Row space="between" maxWidth="350px">
                <Box w="85px">
                  <Select
                    value={this.state.startMonth}
                    onChange={this.change('startMonth')}
                  >
                    <option value={''}>Month</option>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((num, i) => (
                      <option value={num} defaultValue={''} key={i}>
                        {moIndexToName[num - 1]}
                      </option>
                    ))}
                  </Select>
                </Box>
                <Box w="70px">
                  <Select
                    value={this.state.startDay}
                    onChange={this.change('startDay')}
                  >
                    <option>Day</option>
                    {dayArray.map((num, i) => (
                      <option value={num} defaultValue={''} key={i}>
                        {num}
                      </option>
                    ))}
                  </Select>
                </Box>
                <Box w="80px">
                  <Select
                    value={this.state.startYear}
                    onChange={this.change('startYear')}
                  >
                    <option>Day</option>
                    {['2020', '2021', '2022', '2023', '2024'].map((num, i) => (
                      <option value={num} defaultValue={''} key={i}>
                        {num}
                      </option>
                    ))}
                  </Select>
                </Box>
              </Row>
            </Box>
            <Box p={1}>
              <Box p={1}>
                <Text.title>End Date</Text.title>
              </Box>
              <Row space="between" maxWidth="350px">
                <Box w="85px">
                  <Select
                    value={this.state.endMonth}
                    onChange={this.change('endMonth')}
                  >
                    <option value={''}>Month</option>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((num, i) => (
                      <option value={num} defaultValue={''} key={i}>
                        {moIndexToName[num - 1]}
                      </option>
                    ))}
                  </Select>
                </Box>
                <Box w="70px">
                  <Select
                    value={this.state.endDay}
                    onChange={this.change('endDay')}
                  >
                    <option>Day</option>
                    {dayArray.map((num, i) => (
                      <option value={num} defaultValue={''} key={i}>
                        {num}
                      </option>
                    ))}
                  </Select>
                </Box>
                <Box w="80px">
                  <Select
                    value={this.state.endYear}
                    onChange={this.change('endYear')}
                  >
                    <option>Day</option>
                    {['2020', '2021', '2022', '2023', '2024'].map((num, i) => (
                      <option value={num} defaultValue={''} key={i}>
                        {num}
                      </option>
                    ))}
                  </Select>
                </Box>
              </Row>
            </Box>
          </Col>
        </Row>
        <Box>
          <Box p={2}>
            <Text.title size={2}>Orders</Text.title>
          </Box>
          <Box border={`solid 1px ${s.colors.base}`}>
            <OrderList
              orders={ordersInQuestion}
              reviewsByOrderId={this.props.reviews}
              onAdjustmentRequest={this.onAdjustmentRequest}
              onCancelRequest={this.onCancelRequest}
            />
          </Box>
        </Box>
      </Box>
    )
  }
})

const selectors = (state, props) => ({
  locationsByOrg: select.locationsByOrg(state),
  orgs: select.orgList(state),
  // orders: select.ordersByOrg(props.match.params.orgId)(state)
  orders: select.orders(state),
  reviews: select.reviewsByOrderId(state)
})

const actions = (dispatch, props) => ({
  loadOrgReviews: orgId => dispatch(loadReviews(orgId)),
  loadOrgLocations: orgId => dispatch(loadOrgLocations(orgId)),
  loadOrgs: () => dispatch(getOrgEntities()),
  loadOrgOrders: params => dispatch(loadOrderEntities(params)),
  loadOrders: () => dispatch(loadAllOrders()),
  loadConsumers: consumerIds => dispatch(loadConsumerBatch(consumerIds))
})

const Pair = ({ children }) => (
  <Row w={225} space="between" p={1} border={`solid 1px ${s2.colors.purple}`}>
    <Box>{children[0]}</Box>
    <Box>{children[1]}</Box>
  </Row>
)
export default connect(
  selectors,
  actions
)(Reporting)

const OrderList = cc({
  propTypes: { orders: pt.array, reviewsByOrderId: pt.object },
  height: 350,
  render() {
    const {
      orders,
      reviewsByOrderId,
      onAdjustmentRequest,
      onCancelRequest
    } = this.props
    return (
      <List
        noRowsRenderer={() => (
          <Flex justify="center" align="center" height={this.height}>
            No Orders
          </Flex>
        )}
        width={window.innerWidth}
        height={this.height}
        rowCount={orders.length}
        rowHeight={100}
        rowRenderer={({ index, style, key }) => {
          const order = orders[index]
          const review = reviewsByOrderId[(order || {}).id]
          order.consumer = order.consumer || {
            firstName: 'Boostly',
            lastName: 'Consumer',
            phoneNumber: ''
          }
          return order ? (
            <Flex
              height="100px"
              border-bottom={`solid 1px black`}
              key={key}
              style={style}
            >
              <Flex
                w="220px"
                border-right={`solid 1px black`}
                align="center"
                justify="center"
                column
              >
                <Text>{order.org}</Text>
                <Text>
                  {order.timing ? typeToTiming[order.timing.type] : 'missing'}
                </Text>
                <Text>{order.id}</Text>
                <Text>
                  created: {format(order.createdAt.toDate(), 'MM/DD hh:mm a')}
                </Text>
                <Text>
                  fulfill by:{' '}
                  {format(order.fulfillBy.toDate(), 'MM/DD hh:mm a')}
                </Text>
              </Flex>
              <Flex
                column
                w="210px"
                border-right={`solid 1px black`}
                align="center"
                justify="center"
              >
                <Box>
                  <Text>{order.consumer.firstName} </Text>
                  <Text>{order.consumer.lastName} </Text>
                </Box>
                <Box>
                  <Text>{order.consumer.phoneNumber} </Text>
                </Box>
              </Flex>
              <Flex
                column
                w="210px"
                border-right={`solid 1px black`}
                align="center"
                justify="center"
              >
                <Box>
                  <Text
                    error={
                      order.status === 'declined' ||
                      order.status === 'cancelled'
                    }
                  >
                    {order.status}
                  </Text>
                </Box>
                <Flex>
                  <Box
                    display="inline-block"
                    w="20px"
                    pr={1}
                    onClick={() => onAdjustmentRequest(order.id)}
                    cursor="pointer"
                  >
                    <EditIcon />
                  </Box>
                  <Text>{toPrice(order.cost.total)} </Text>
                  <Box
                    display="inline-block"
                    w="20px"
                    pl={1}
                    onClick={() => onCancelRequest(order.id)}
                    cursor="pointer"
                  >
                    <CancelIcon />
                  </Box>
                </Flex>
                {order.adjustmentTotal && (
                  <Box>
                    <Text>Adjusted: ({toPrice(order.adjustmentTotal)}) </Text>
                  </Box>
                )}
                <Box>
                  <Text>items: {order.items.length} </Text>
                </Box>
              </Flex>
              <Flex
                column
                w="450px"
                border-right={`solid 1px black`}
                p={1}
                overflow="auto"
              >
                {review ? (
                  <Box>
                    <Box>
                      <Box is="span" textDecoration="underline">
                        Rating:
                      </Box>{' '}
                      <Text
                        success={review.rating >= 9}
                        error={review.rating <= 4}
                      >
                        {review.rating}
                      </Text>
                    </Box>
                    <Box>
                      <Box is="span" textDecoration="underline">
                        Feedback:
                      </Box>{' '}
                      {review.feedbackText}
                    </Box>
                  </Box>
                ) : (
                  <Box>No Review</Box>
                )}
              </Flex>
            </Flex>
          ) : (
            undefined
          )
        }}
      />
    )
  }
})
