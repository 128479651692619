import React from 'react'
import { connect } from 'react-redux'
import cc from 'create-react-class'
import pt from 'prop-types'
import { css } from 'glamor'
import { isPast } from 'date-fns'
import Loading from 'App/shared/Loading'
import { Flex } from 'boostly-ui'
import { Row, Col, Button, Box, Text } from 'boostly-ui2'
import { formatPhone } from 'utils/rendering'
import OrderListing from './components/OrderListing'
import {
  approveOrder,
  loadNewOrderEntities,
  loadConsumerEntity,
  loadLocationEntity,
  select
} from './state'

const palette = {
  primaryPurple: '#8147FF',
  secondaryPurple: '#5B27A2',
  blue: '#419BFF',
  aqua: '#00EEFF',
  black: '#08080C'
}
const to = palette.primaryPurple
const from = palette.secondaryPurple
let flash = css.keyframes({
  '0%': { background: from },
  '60%': { background: to },
  '100%': { background: from }
})
const flashProps = (direction = 'bottom') => ({
  width: '100%',
  animation: `${flash} 0.5s infinite`
})

const Listing = cc({
  getInitialState() {
    return { shouldAlert: this.shouldAlert(this.props.order) }
  },
  shouldAlert(order) {
    return isPast(order.whenToAlert && order.whenToAlert.toDate())
  },
  manageAlert(order) {
    window.setTimeout(() => {
      if (this.shouldAlert(order)) {
        this.setState(prev => ({ shouldAlert: true }))
      } else {
        this.manageAlert(order)
      }
    }, 500)
  },
  componentDidMount() {
    this.manageAlert(this.props.order)
  },
  render() {
    const { order, location } = this.props
    return (
      <Box key={order.id} mt="2">
        <Flex
          color="white"
          p={1}
          justify="space-between"
          {...(this.state.shouldAlert
            ? flashProps()
            : { bg: palette.primaryPurple })}
        >
          <span>
            {(location || {}).name} - {order.id}
          </span>
          <Box
            is="a"
            display="inline"
            color={'white'}
            href={`tel:${(location || {}).phoneNumber}`}
          >
            {formatPhone((location || {}).phoneNumber || '')}
          </Box>
        </Flex>
        <OrderListing details={order} />
      </Box>
    )
  }
})
const OrderGodMode = cc({
  propTypes: { newOrders: pt.array, locationsById: pt.object },
  getInitialState() {
    return { newOrders: [] }
  },
  componentDidMount() {
    this.props.loadOrders()
  },
  ordersMissingConsumers: {},
  manageMissingConsumers(orders) {
    orders.forEach(order => {
      const isFetching = this.ordersMissingConsumers[order.id]
      const hasConsumer = order.consumer && order.consumer.id
      if (!hasConsumer && !isFetching) {
        this.props.loadConsumer(order.consumer)
        this.ordersMissingConsumers[order.id] = new Date()
      } else if (hasConsumer && isFetching) {
        delete this.ordersMissingConsumers[order.id]
      }
    })
  },
  locationDetailsMissing: {},
  manageMissingLocationDetails(orders) {
    orders.forEach(order => {
      const isFetching = this.locationDetailsMissing[order.location]
      const hasDetails = this.props.locationsById[order.location]
      if (!hasDetails && !isFetching) {
        this.props.loadLocationDetails({
          orgId: order.org,
          locId: order.location
        })
        this.locationDetailsMissing[order.location] = new Date()
      } else if (hasDetails && isFetching) {
        delete this.locationDetailsMissing[order.location]
      }
    })
  },
  componentWillReceiveProps({ newOrders }) {
    this.manageMissingConsumers(newOrders)
    this.manageMissingLocationDetails(newOrders)
  },
  render() {
    const { newOrders } = this.props

    const ordersWithConsumerAndLocationData = newOrders.filter(
      no =>
        typeof no.consumer !== 'string' && this.props.locationsById[no.location]
    )
    if (this.props.isLoading) {
      return <Loading />
    }
    if (!newOrders.length) {
      return (
        <Col x textAlign="center" pt="10%">
          <Text.title size={4} pb={2}>
            All is well 👌
          </Text.title>
          <Box
            is="img"
            p={1}
            alt="All Good"
            w="300px"
            src="https://media.giphy.com/media/3oEjHBUwuSGbFS5iAE/giphy.gif"
          />
        </Col>
      )
    }

    return (
      <Box>
        {ordersWithConsumerAndLocationData.length ? (
          <Box>
            {ordersWithConsumerAndLocationData.map((no, i) => (
              <Box key={no.id}>
                {
                  <Listing
                    order={no}
                    location={this.props.locationsById[no.location]}
                  />
                }
                <Row x="end">
                  <Box bg={'white'} w="300px" pr={2} py={2}>
                    <Button onClick={() => this.props.approveOrder(no.id)}>
                      Approve
                    </Button>
                  </Box>
                </Row>
              </Box>
            ))}
          </Box>
        ) : (
          <Loading />
        )}
      </Box>
    )
  }
})

const selector = (state, props) => ({
  locationsById: select.locationsById(props.match.params)(state),
  newOrders: select.pendingOrders(props.match.params)(state),
  isLoading: !state.godModeLoaded
})

const actions = (dispatch, props) => {
  return {
    loadConsumer: id => dispatch(loadConsumerEntity(id)),
    loadLocationDetails: ({ orgId, locId }) =>
      dispatch(loadLocationEntity({ orgId, locId })),
    loadOrders: () => dispatch(loadNewOrderEntities()),
    approveOrder: orderId => dispatch(approveOrder({ orderId }))
  }
}

export default connect(
  selector,
  actions
)(OrderGodMode)
