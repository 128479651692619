import React from 'react'
import cc from 'create-react-class'
import pt from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Modal, Input } from 'boostly-ui'
import { settings as s, Row, Box, Text, Button } from 'boostly-ui2'
import { createNewOrg, getOrgEntities } from 'App/state'
import ListItem from 'App/shared/ListItem'

const OrgList = cc({
  propTypes: { dispatch: pt.func, orgs: pt.array },
  getInitialState() {
    return { showCreateModal: false, newOrgName: '' }
  },
  componentDidMount() {
    this.props.dispatch(getOrgEntities())
  },
  toggleCreateModal() {
    this.setState({ showCreateModal: !this.state.showCreateModal })
  },
  onOrgNameChange({ target }) {
    this.setState({ newOrgName: target.value })
  },
  createNew() {
    this.props.dispatch(createNewOrg({ name: this.state.newOrgName }))
    this.toggleCreateModal()
  },
  render() {
    return (
      <Box>
        <Row p={2} y space="between">
          <Text.title fontSize={4}>Organizations</Text.title>
          <Row x y onClick={this.toggleCreateModal} cursor="pointer">
            <Text.title size={5} mr={2}>
              +
            </Text.title>
            <Text.title>Create</Text.title>
          </Row>
        </Row>
        <Modal
          contentLabel="holla"
          isOpen={this.state.showCreateModal}
          onRequestClose={this.toggleCreateModal}
        >
          <Box is="form" onSubmit={this.createNew}>
            <Box px={3} pb={2}>
              <Text.title>Organization Name</Text.title>
              <Box pb={1} />
              <Input
                value={this.state.newOrgName}
                onChange={this.onOrgNameChange}
              />
            </Box>
            <Box p={2}>
              <Button type="submit">Create Now</Button>
            </Box>
          </Box>
        </Modal>
        <Box>
          {this.props.orgs.map((org, i) => (
            <Box w={'100%'} key={i}>
              <Link to={`/manage-org/${org.id}`}>
                <ListItem>{org.name}</ListItem>
              </Link>
            </Box>
          ))}
        </Box>
      </Box>
    )
  }
})

export default connect(({ entities }) => ({
  orgs: Object.keys(entities.orgs).map(key => entities.orgs[key])
}))(OrgList)
