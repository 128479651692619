import { createAsyncActions as aa, createReducer } from 'state/utils'
import { callStore, callApi } from 'App/state'
import { startOfToday } from 'date-fns'
import Promise from 'bluebird'
import { dbRef } from 'App/state/utils'

const loadConsumer = id =>
  dbRef
    .collection('consumers')
    .doc(id)
    .get()
    .then(result => ({ id: result.id, ...result.data() }))

export const loadConsumerBatch = consumerIds => (dispatch, getState) => {
  const state = getState()
  const idsToLoad = consumerIds.reduce(
    (ids, id) => (state.entities.consumers[id] ? ids : [...ids, id]),
    []
  )

  return Promise.all(idsToLoad.map(loadConsumer)).then(results => {
    if (results.length) {
      dispatch({
        type: 'BATCHED_CONSUMERS_SUCCESS',
        payload: {
          entities: {
            consumers: results.reduce(
              (map, entity) => ({ ...map, [entity.id]: entity }),
              {}
            )
          }
        }
      })
    }
  })
}

const ADD_ORDER_ADJUSTMENT_ACTIONS = aa('ADD_ORDER_ADJUSTMENT')
export const addOrderAdjustment = ({
  orderId,
  amount,
  type,
  reason,
  reasonType
}) =>
  callApi({
    method: 'POST',
    types: ADD_ORDER_ADJUSTMENT_ACTIONS,
    endpoint: 'orders/adjust',
    data: { orderId, amount: Number(amount), type, reason, reasonType }
  })

const CANCEL_ORDER_ACTIONS = aa('CANCEL_ORDER')
export const cancelOrder = ({ orderId, reason }) =>
  callApi({
    method: 'POST',
    types: CANCEL_ORDER_ACTIONS,
    endpoint: 'orders/cancel',
    data: { orderId, reason }
  })

const LOAD_ORDER_ENTITIES_ACTIONS = aa('LOAD_ORDER_ENTITIES')
export const loadOrderEntities = ({ orgId, startDate }) =>
  callStore({
    types: LOAD_ORDER_ENTITIES_ACTIONS,
    schema: { name: 'orders' },
    query: {
      method: 'onSnapshot',
      collection: 'orders',
      where: [`org == ${orgId}`, ['createdAt', '>', startDate]]
    },
    meta: { orgId }
  })

const LOAD_ALL_ORDER_ENTITIES_ACTIONS = aa('LOAD_ALL_ORDER_ENTITIES')
export const loadAllOrders = () =>
  callStore({
    types: LOAD_ALL_ORDER_ENTITIES_ACTIONS,
    schema: { name: 'orders' },
    query: {
      method: 'onSnapshot',
      collection: 'orders',
      where: [['createdAt', '>', startOfToday()]]
    }
  })

const LOAD_ORG_LOCATIONS_ACTIONS = aa('LOAD_ORG_LOCATIONS')
export const loadOrgLocations = orgId =>
  callStore({
    types: LOAD_ORG_LOCATIONS_ACTIONS,
    schema: { name: 'locations' },
    query: {
      method: 'onSnapshot',
      collection: 'orgs',
      doc: orgId,
      subcollections: [{ collection: 'locations' }]
    },
    meta: { orgId }
  })

const LOAD_REVIEWS_ACTIONS = aa('LOAD_REVIEWS')
export const loadReviews = orgId =>
  callStore({
    types: LOAD_REVIEWS_ACTIONS,
    schema: { name: 'reviews', key: 'orderId' },
    query: {
      method: 'onSnapshot',
      collection: 'reviews',
      where: [`organizationId == ${orgId}`]
    },
    meta: { orgId }
  })

export const select = {
  orgList: ({ entities }) =>
    Object.keys(entities.orgs).map(key => entities.orgs[key]),
  locationsByOrg: ({ locationsByOrg, entities }) =>
    Object.keys(locationsByOrg).reduce((lbo, key) => {
      return {
        ...lbo,
        [key]: locationsByOrg[key].map(locId => entities.locations[locId])
      }
    }, {}),
  ordersByOrg: orgId => ({ ordersByOrg, entities }) =>
    ordersByOrg[orgId]
      ? ordersByOrg[orgId].map(orderId => ({
        ...entities.orders[orderId],
        consumer:
            entities.consumers[entities.orders[orderId].consumer] ||
            entities.orders[orderId].consumer
      }))
      : [],
  orders: ({ orders, entities }) =>
    orders.map(orderId => ({
      ...entities.orders[orderId],
      consumer:
        entities.consumers[entities.orders[orderId].consumer] ||
        entities.orders[orderId].consumer
    })),
  reviewsByOrderId: ({ entities }) => entities.reviews
}

export default {
  locationsByOrg: createReducer(
    {},
    {
      [LOAD_ORG_LOCATIONS_ACTIONS[1]]: (state, { payload, meta }) => ({
        ...state,
        [meta.orgId]: [...(state[meta.orgId] || []), ...payload.ids]
      })
    }
  ),
  ordersByOrg: createReducer(
    {},
    {
      [LOAD_ORDER_ENTITIES_ACTIONS[1]]: (state, { payload, meta }) => ({
        ...state,
        [meta.orgId]: [...(state[meta.orgId] || []), ...payload.ids]
      })
    }
  ),
  orders: createReducer([], {
    [LOAD_ALL_ORDER_ENTITIES_ACTIONS[1]]: (state, { payload, meta }) =>
      payload.ids,
    [LOAD_ORDER_ENTITIES_ACTIONS[1]]: (state, { payload, meta }) => payload.ids
  })
}
// all orgs
// locations by org id
// orders by creation date
