import './style'
import React from 'react'
import { Route, Link } from 'react-router-dom'
import { Flex, Box, settings as s } from 'boostly-ui'
import {
  OrgList,
  ManageOrg,
  ManageLocation,
  ManageMenu,
  ManageCampaign,
  Login,
  Reporting,
  OrderGodMode
} from './views'
import PrivateRoute from './shared/PrivateRoute'

const navHeight = '50px'
const Navigation = () => (
  <Box height={navHeight} bg="#08080C">
    <Flex
      maxWidth={s.primaryContainerWidth}
      margin="0 auto"
      height="100%"
      align="center"
    >
      <Link to="/organizations">
        <Flex align="center" justify="center">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/boostly-live.appspot.com/o/assets%2Fbrand%2Flogo-logomark-white.png?alt=media&amp;token=0bea97e8-dad2-4149-84b8-234acef44b50"
            height={'40px'}
          />
        </Flex>
      </Link>
    </Flex>
  </Box>
)

const withFixedWidthContainer = children => (
  <Box maxWidth={s.primaryContainerWidth} margin="0 auto" py={1} height="100%">
    {children}
  </Box>
)
const App = () => (
  <Box height="100%">
    <Navigation />
    <Route path="/login" component={Login} />
    <PrivateRoute
      path="/organizations"
      component={props => withFixedWidthContainer(<OrgList {...props} />)}
    />
    <PrivateRoute
      path="/manage-org/:id"
      exact
      component={props => withFixedWidthContainer(<ManageOrg {...props} />)}
    />
    <PrivateRoute
      path="/manage-org/:orgId/manage-location/:locId"
      component={props => <ManageLocation {...props} />}
    />
    <PrivateRoute
      path="/manage-org/:orgId/manage-menu/:menuId"
      component={props => withFixedWidthContainer(<ManageMenu {...props} />)}
    />
    <PrivateRoute
      path="/manage-org/:orgId/manage-campaign/:campaignId"
      component={props =>
        withFixedWidthContainer(<ManageCampaign {...props} />)
      }
    />
    <PrivateRoute
      path="/reporting"
      component={props => <Reporting {...props} />}
    />
    <PrivateRoute
      path="/godmode"
      component={props => <OrderGodMode {...props} />}
    />
  </Box>
)

export default App
