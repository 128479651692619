import { combineReducers } from 'redux'
import { createAsyncActions as aa, createReducer } from 'state/utils'
import {
  orgRef,
  addEntitiesToStore,
  docToEntity,
  docsToEntities
} from './utils'
export const callStore = require('config/api').callStore
export const callApi = require('config/api').callApi

const initialEntityState = {
  orgs: {},
  locations: {},
  menus: {},
  categories: {},
  optionSets: {},
  options: {},
  menuItems: {},
  pickUpSchedules: {},
  deliverySchedules: {},
  admins: {},
  orders: {},
  consumers: {},
  reviews: {},
  campaigns: {}
}
const createReducerFn = name => (
  state = initialEntityState[name],
  { payload }
) =>
  payload && payload.entities && payload.entities[name]
    ? { ...state, ...payload.entities[name] }
    : state

const entities = combineReducers({
  orgs: createReducerFn('orgs'),
  locations: createReducerFn('locations'),
  menus: createReducerFn('menus'),
  categories: createReducerFn('categories'),
  optionSets: createReducerFn('optionSets'),
  options: createReducerFn('options'),
  menuItems: createReducerFn('menuItems'),
  pickUpSchedules: createReducerFn('pickUpSchedules'),
  deliverySchedules: createReducerFn('deliverySchedules'),
  admins: createReducerFn('admins'),
  orders: createReducerFn('orders'),
  consumers: createReducerFn('consumers'),
  reviews: createReducerFn('reviews'),
  campaigns: createReducerFn('campaigns')
})

export const createNewOrg = orgObj => dispatch => orgRef.add(orgObj)

export const getOrgEntity = id => dispatch =>
  orgRef
    .doc(id)
    .onSnapshot(doc => dispatch(addEntitiesToStore('orgs', docToEntity(doc))))

const getOrgData = orgId => ({ entities, adminsByOrg, campaignsByOrg }) => {
  const org = entities.orgs[orgId] || {}
  const orgAdmins = adminsByOrg[orgId]
  const orgCampaigns = campaignsByOrg[orgId]
  return {
    ...org,
    locations: (org.locations || []).map(id => entities.locations[id] || {}),
    menus: (org.menus || []).map(id => entities.menus[id] || {}),
    admins: (orgAdmins || []).map(id => entities.admins[id]),
    campaigns: (orgCampaigns || []).map(id => entities.campaigns[id])
  }
}

export const selectors = { getOrgData }

export const getOrgEntities = () => dispatch =>
  orgRef.onSnapshot(({ docs }) =>
    dispatch(addEntitiesToStore('orgs', docsToEntities(docs)))
  )

const LOAD_GLOBAL_SETTINGS_ACTIONS = aa('LOAD_GLOBAL_SETTINGS')
export const loadGlobalSettings = () =>
  callStore({
    types: LOAD_GLOBAL_SETTINGS_ACTIONS,
    query: { method: 'get', collection: 'settings', doc: 'primary' }
  })

export default combineReducers({
  entities,
  settings: createReducer(
    {},
    {
      [LOAD_GLOBAL_SETTINGS_ACTIONS[1]]: (state, { payload }) => payload.data()
    }
  ),
  ...require('./session').default,
  ...require('App/views/ManageMenu/state').default,
  ...require('App/views/ManageLocation/actions').default,
  ...require('App/views/ManageOrg/actions').default,
  ...require('App/views/Reporting/state').default,
  ...require('App/views/OrderGodMode/state').default
})
