import { createAsyncActions as aa, createReducer } from 'state/utils'
import { auth } from 'config/api'
import { callStore, callApi } from 'App/state'
import { addEntitiesToStore, docsToEntities, orgRef } from 'App/state/utils'

const GO_LIVE_ACTIONS = aa('GO_LIVE')
export const goLive = ({ orgId, newId }) =>
  callApi({
    method: 'POST',
    types: GO_LIVE_ACTIONS,
    endpoint: 'data-transfers/transferOrgData',
    data: {
      orgId,
      newId
    }
  })

export const createNewLocation = orgId => locationObj => (
  dispatch,
  getState
) => {
  orgRef
    .doc(orgId)
    .collection('locations')
    .add(locationObj)
    .then(doc => {
      orgRef.doc(orgId).update({
        locations: [
          ...(getState().entities.orgs[orgId].locations || []),
          doc.id
        ]
      })
    })
}

export const getLocationEntities = orgId => dispatch =>
  orgRef
    .doc(orgId)
    .collection('locations')
    .onSnapshot(({ docs }) =>
      dispatch(addEntitiesToStore('locations', docsToEntities(docs)))
    )

const GET_CAMPAIGN_ENTITIES_ACTIONS = aa('GET_CAMPAIGN_ENTITIES')
export const getCampaignEntities = orgId =>
  callStore({
    types: GET_CAMPAIGN_ENTITIES_ACTIONS,
    schema: { name: 'campaigns' },
    query: {
      method: 'onSnapshot',
      collection: 'campaigns',
      where: [['orgId', '==', orgId]]
    },
    meta: { orgId }
  })

export const createNewMenu = orgId => menuObj => (dispatch, getState) => {
  orgRef
    .doc(orgId)
    .collection('menus')
    .add(menuObj)
    .then(doc => {
      orgRef.doc(orgId).update({
        menus: [...(getState().entities.orgs[orgId].menus || []), doc.id]
      })
    })
}

const CREATE_CAMPAIGN_ACTIONS = aa('CREATE_CAMPAIGN')
export const createCampaignEntity = campaign =>
  callStore({
    types: CREATE_CAMPAIGN_ACTIONS,
    query: { method: 'add', collection: 'campaigns', data: campaign }
  })

export const makeMenuCopy = orgId => ({ name, menuToCopy }) => (
  dispatch,
  getState
) => {
  const thisOrgRef = orgRef.doc(orgId)
  const menuToCopyRef = thisOrgRef.collection('menus').doc(menuToCopy)
  // add menu to menus collection
  thisOrgRef
    .collection('menus')
    .add({ name })
    .then(doc => {
      // add to list of menu ids
      thisOrgRef.update({
        menus: [...(getState().entities.orgs[orgId].menus || []), doc.id]
      })
      const newMenuRef = thisOrgRef.collection('menus').doc(doc.id)
      ;[
        // get categories from old, then post to new menu
        'categories',
        // get menuItems from old, then post to new menu
        'menuItems',
        // get optionSets from old, then post to new menu
        'optionSets',
        // get options from old, then post to new menu
        'options'
      ].forEach(menuCollectionName => {
        menuToCopyRef
          .collection(menuCollectionName)
          .get()
          .then(snapshot => {
            snapshot.forEach(doc => {
              newMenuRef
                .collection(menuCollectionName)
                .doc(doc.id)
                .set(doc.data())
            })
          })
      })
    })
}

export const getMenuEntities = orgId => dispatch =>
  orgRef
    .doc(orgId)
    .collection('menus')
    .onSnapshot(({ docs }) =>
      dispatch(addEntitiesToStore('menus', docsToEntities(docs)))
    )

const GET_ADMIN_ENTITIES_ACTIONS = aa('GET_ADMIN_ENTITIES')
export const getAdminEntities = orgId =>
  callStore({
    types: GET_ADMIN_ENTITIES_ACTIONS,
    schema: { name: 'admins' },
    query: {
      method: 'onSnapshot',
      collection: 'admins',
      where: [`org == ${orgId}`]
    },
    meta: { orgId }
  })

export const createAdminEntity = ({ email, password, data }) => dispatch => {
  auth.createUserWithEmailAndPassword(email, password)
  dispatch(
    callStore({
      types: aa('CREATE_ADMIN_ENTITY'),
      query: { method: 'set', collection: 'admins', doc: email, data }
    })
  )
}

const dedupe = list =>
  Object.keys(
    list.reduce(
      (map, id) => ({
        ...map,
        [id]: true
      }),
      {}
    )
  )
export default {
  adminsByOrg: createReducer(
    {},
    {
      [GET_ADMIN_ENTITIES_ACTIONS[1]]: (state, { payload, meta }) => ({
        ...state,
        [meta.orgId]: dedupe([...(state[meta.orgId] || []), ...payload.ids])
      })
    }
  ),
  campaignsByOrg: createReducer(
    {},
    {
      [GET_CAMPAIGN_ENTITIES_ACTIONS[1]]: (state, { payload, meta }) => ({
        ...state,
        [meta.orgId]: dedupe([...(state[meta.orgId] || []), ...payload.ids])
      })
    }
  )
}
