import React, { useState, Fragment as _ } from 'react'
import pt from 'prop-types'
import { settings as s } from 'boostly-ui'

const { Provider, Consumer } = React.createContext({ activeIndex: 0 })

const tabStyles = {
  display: 'inline-block',
  padding: 10,
  cursor: 'pointer',
  color: s.colors.textOnLight
}

const styles = {
  tab: tabStyles,
  activeTab: {
    ...tabStyles,
    borderRadius: '3px',
    backgroundColor: s.colors.base
  },
  disabledTab: { ...tabStyles, opacity: 0.25, cursor: 'default' }
}
export const Tab = ({ children }) => {
  return (
    <Consumer>
      {context => {
        const index = context.getTabIndex()
        const isActive = index === context.activeIndex
        return (
          <div
            {...{
              onClick: () => context.onActivate(index),
              style: isActive ? styles.activeTab : styles.tab
            }}
          >
            {children}
          </div>
        )
      }}
    </Consumer>
  )
}
Tab.propTypes = {
  children: pt.node
}

export const TabPanel = props => {
  return (
    <Consumer>
      {context => {
        const index = context.getPanelIndex()
        const isActive = index === context.activeIndex
        return isActive ? <_>{props.children}</_> : undefined
      }}
    </Consumer>
  )
}
TabPanel.propTypes = {
  children: pt.node
}

const makeRegistrar = () => {
  let count = 0
  return () => {
    return count++
  }
}
export const Tabs = ({ children }) => {
  const [activeIndex, setIndex] = useState(0)
  return (
    <Provider
      value={{
        activeIndex: activeIndex,
        onActivate: setIndex,
        getTabIndex: makeRegistrar(),
        getPanelIndex: makeRegistrar()
      }}
    >
      {children}
    </Provider>
  )
}

Tabs.propTypes = {
  children: pt.node
}
