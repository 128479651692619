import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import cc from 'create-react-class'
import pt from 'prop-types'
import { RingLoader } from 'react-spinners'
import { Row, settings as s } from 'boostly-ui2'
import { getMe, getAdminEntity } from 'App/state/session'

const PrivateRoute = cc({
  propTypes: { authorizedRoles: pt.array },
  userDataIsFetched() {
    return !this.props.session.isActive || this.props.user
  },
  componentDidMount() {
    const { session, getLoggedUserEntity } = this.props
    if (session.isActive) {
      getLoggedUserEntity(session.uid)
    }
  },
  isAuthorized({ match }) {
    const { user } = this.props
    if (!user) {
      return false
    } else if (user.roles.master) {
      return true
    } else {
      return false
    }
  },
  render() {
    const { component: Component, ...rest } = this.props
    return (
      <Route
        {...rest}
        render={props =>
          this.userDataIsFetched() ? (
            this.isAuthorized(props) ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{ pathname: '/login', state: { from: props.location } }}
              />
            )
          ) : (
            <Row pt="10%" x>
              <RingLoader color={s.colors.purple} />
            </Row>
          )
        }
      />
    )
  }
})

const selector = (state, props) => ({
  user: getMe(state),
  session: state.session
})

const actions = dispatch => ({
  getLoggedUserEntity: uid => dispatch(getAdminEntity(uid))
})

export default connect(
  selector,
  actions
)(PrivateRoute)
